import moment from 'moment/moment';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { leadsAPI } from '../../API/Leads';
import { dateFormatTable } from '../../Utils/constants';
import ButtonControl from '../Common/Button';
import DataTableControl from '../Common/DataTable';
import SearchFilter from '../Common/SearchFilter';
import Toaster from '../Common/Toaster';

const LeadComponent = ({ details, id }) => {
  const location = useLocation();
  const navigation = useNavigate();
  const { pathname } = location;
  const [filter, setFilter] = useState({});
  const [filterValue, setFilterValue] = useState({});
  const [loading, setLoading] = useState(false);
  const [toaster, setToaster] = useState(false);
  const [error, setError] = useState({
    title: '',
    message: '',
    timeOut: '',
    type: '',
    subTitle: '',
  });

  const getTableData = async (filter) => {
    setLoading(true);
    filter.$page = filter.$page || 1;
    filter.$limit = filter.$limit || 10;
    filter.filter = filter.filter || {};
    filter.filter = {
      ...filter.filter,
      property: id || pathname?.split('/')[4],
    };
    const res = await leadsAPI?.getLeads(filter);
    if (res || !res) setLoading(false);
    if (res && res?.status === true) {
      toaster && setToaster(false);
      return res?.data;
    } else {
      setToaster(true);
      setError({
        ...error,
        title: 'API Fail',
        message: res?.data?.message || 'Something went to wrong!',
        type: 'error',
      });
      return [];
    }
  };

  const handleChange = (e) => {
    setFilterValue({
      ...filterValue,
      ...e,
    });
  };

  const handleAddFilter = () => {
    let filterData = {};
    Object.keys(filterValue).map((keyName) => {
      if (filterValue[keyName]) {
        filterData[keyName] = filterValue[keyName];
      }
    });
    setFilter(filterData);
  };

  const handleCloseToaster = () => {
    setToaster(false);
  };

  const headingData = [
    {
      key: 'name',
      sortable: true,
      header: 'Name',
    },
    {
      key: 'Date',
      sortable: true,
      header: 'Date',
      render: (value) => {
        return (
          <span>
            {' '}
            {(value && moment(value).format(dateFormatTable)) || 'N/A'}
          </span>
        );
      },
    },
    {
      key: 'email',
      sortable: true,
      header: 'Email',
    },
    {
      key: 'mail_link',
      sortable: true,
      header: 'Mail Link',
      render: (value) => {
        return (
          <div className="table-action">
            <span className="cursor-pointer">
              <a href={value} target="_blank">
                {value}
              </a>
            </span>
          </div>
        );
      },
    },
  ];

  const handleRedirectProperty = () => {
    if (id || pathname?.split('/')[4]) {
      navigation(
        `/properties/property-detail/${id || pathname?.split('/')[4]}`
      );
    }
  };

  return (
    <div className="applicants-table-wrap">
      {toaster && (
        <Toaster
          title={error?.title}
          message={error?.message}
          type={error?.type}
          handleCloseToaster={handleCloseToaster}
        />
      )}
      <div className="applicants-listing-title">
        <h4>{details?.address}</h4>
        <ButtonControl onClick={handleRedirectProperty}>View</ButtonControl>
      </div>
      {/* <p className="mt--15">{details?.address}</p> */}
      <br></br>
      <SearchFilter
        handleChange={(e) => handleChange({ name: e })}
        handleSearch={handleAddFilter}
        value={filterValue?.name || ''}
      />
      <div className="table-nowrap mb-30">
        <hr></hr>
        <DataTableControl
          filter={filter}
          columns={headingData}
          getData={getTableData}
          sortable
          showPagination
          loading={loading}
        />
      </div>
    </div>
  );
};

export default LeadComponent;
