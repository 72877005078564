import { get, patch, post } from '../base';
const endPoint = 'admin/property';

const getStaticProperties = async () => {
  const response = await get(`${endPoint}/static-data`);
  return response;
};

const getSingleProperty = async (id) => {
  const response = await get(`${endPoint}/${id}`);
  return response;
};

const getAllProperties = async (params) => {
  const response = await get(`${endPoint}`, params);
  return response;
};

const getAllFileteredProperties = async (body) => {
  const response = await post(`${endPoint}/all`, body);
  return response;
};

const updateProperty = async (id, body, header) => {
  const response = await patch(`${endPoint}/${id}`, body, header);
  return response;
};

const updatePropertyStatus = async (id, body) => {
  const response = await patch(`${endPoint}/status/${id}`, body);
  return response;
};

const unListProperty = async (id) => {
  const response = await patch(`${endPoint}/request-un-list/${id}`);
  return response;
};

const getCountOfApplicantAndLead = async (id) => {
  const response = await get(`${endPoint}/lead-applicant-count/${id}`);
  return response;
};

export const propertiesAPI = {
  getSingleProperty,
  getAllProperties,
  getAllFileteredProperties,
  updateProperty,
  updatePropertyStatus,
  getStaticProperties,
  unListProperty,
  getCountOfApplicantAndLead,
};
