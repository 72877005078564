import { DatePicker, DatePickerInput } from '@carbon/react';
import React from 'react';
import { dateFormat } from '../../../Utils/constants';
export default function DatePickerControl(props) {
  return (
    <DatePicker dateFormat={dateFormat} {...props} datePickerType="single">
      <DatePickerInput
        id="date-picker-range-start"
        placeholder="dd/mm/yyyy"
        type="date"
        {...props}
      />
    </DatePicker>
  );
}
