import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SiteHeading from '../../Common/SiteHeading';
import LandlordDetailComponent from '../../Landlord/LandlordDetail';
import { breadcrumb } from '../../../Utils/breadcrumbMenus';
import MainLoader from '../../Common/Loader/MainLoader';
import Toaster from '../../Common/Toaster';
import { landlordAPI } from '../../../API/Landlord';

let customMenu = [];
const LandlordDetail = () => {
  const location = useLocation();
  const { state, pathname } = location;
  const [details, setDetails] = useState({});
  const [toaster, setToaster] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastError, setToastError] = useState({});

  useEffect(() => {
    if (details?.first_name) {
      if (!customMenu.some(({ title }) => title === details?.first_name)) {
        customMenu = [];
        customMenu = breadcrumb.landlordDetailsPage.slice();
        customMenu.push({
          title: details?.first_name,
          path: '/landlord/name',
          redirect: false,
        });
      }
    }
  }, [details]);

  useEffect(() => {
    if (state || pathname?.split('/')[3]) {
      handleGetLandlordValue(state || pathname?.split('/')[3]);
    }
  }, [state]);

  const handleGetLandlordValue = async (id) => {
    setLoading(true);
    const res = await landlordAPI?.getSingleLandlord(id);
    if (res && res?.status === true) {
      setDetails(res?.data || {});
      toaster && setToaster(false);
      setLoading(false);
    } else {
      setToastError({
        title: 'API Fail',
        message: res?.data || 'Something went to wrong!',
        type: 'error',
      });
      setToaster(true);
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <MainLoader />}
      {toaster && (
        <Toaster
          title={toastError?.title}
          type={toastError?.type}
          message={toastError?.message}
          handleCloseToaster={() => setToaster(false)}
        />
      )}
      <SiteHeading
        Heading={`${details?.first_name} ${details?.last_name}`}
        menu={customMenu}
      ></SiteHeading>
      <LandlordDetailComponent {...{ details }} />
    </>
  );
};

export default LandlordDetail;
