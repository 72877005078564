import { useNavigate } from 'react-router-dom';
import { userAuth } from '../Utils/common';

function PrivateRoute({ children }) {
  const isLogged = userAuth();
  const navigate = useNavigate();

  if (isLogged) {
    return children;
  } else {
    navigate('/login');
    return null;
  }
}

export default PrivateRoute;
