import React, { useEffect, useState } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import { FormLabel, TextInput } from '@carbon/react';

export default function RangePicker(props) {
  const { value, handleChange, labelText } = props || {};
  const [dates, setDates] = useState(
    value.startDate && value.endDate
      ? value : {
        startDate: moment().subtract(30, 'days'),
        endDate: moment(),
      }
  );

  useEffect(() => {
    if (dates?.startDate && dates?.endDate) {
      handleChange(dates);
    }
  }, [dates]);

  // When click on apply then this method is called
  // const handleApply = (e) => {
  //   console.log('e apply', e);
  // };

  // const handleEvent = (e) => {
  //   console.log('e evet', e);
  // };

  // When user select any dates or option then this method is called
  // const handleCallBack = (e, dates) => {
  //   console.log('e call', e);
  //   console.log('dates', dates);
  // };

  // when date modal close this method called
  const handleHide = (e, dateValue) => {
    setDates({
      startDate: dateValue?.startDate,
      endDate: dateValue?.endDate,
      label: e?.target?.value || '',
    });
  };

  // When click on cancel then this method is called
  // const handleCancel = (e, dates) => {
  //   console.log('e cancel', e);
  //   console.log('dates', dates);
  // };

  return (
    <div className="datepicker-wrapper">
      {
        labelText && <FormLabel>{labelText}</FormLabel>
      }
      <DateRangePicker
        // onApply={handleApply}
        // onEvent={handleEvent}
        // onCallback={handleCallBack}
        // onCancel={handleCancel}
        onHide={handleHide}
        initialSettings={{
          startDate: dates?.startDate
            ? moment(dates?.startDate)
            : moment().subtract(30, 'days'),
          endDate: dates?.endDate ? moment(dates?.endDate) : moment(),
          alwaysShowCalendars: true,
          autoApply: true,
          showCustomRangeLabel: true,
          ranges: {
            Today: [moment().toDate(), moment().toDate()],
            Yesterday: [
              moment().subtract(1, 'days').toDate(),
              moment().subtract(1, 'days').toDate(),
            ],
            'Last 7 Days': [
              moment().subtract(6, 'days').toDate(),
              moment().toDate(),
            ],
            'Last 30 Days': [
              moment().subtract(29, 'days').toDate(),
              moment().toDate(),
            ],
            'This Month': [
              moment().startOf('month').toDate(),
              moment().endOf('month').toDate(),
            ],
            'Last Month': [
              moment().subtract(1, 'month').startOf('month').toDate(),
              moment().subtract(1, 'month').endOf('month').toDate(),
            ],
          },
        }}
      >
        <TextInput />
      </DateRangePicker>
    </div>
  );
}
