import React, { useEffect, useState } from 'react';
import { breadcrumb } from '../../../Utils/breadcrumbMenus';
import SiteHeading from '../../Common/SiteHeading';
import StatisticsComponent from '../../Home/statistics';
import ChartsComponent from '../../Home/charts';
import { leadsAPI } from '../../../API/Leads';
import { applicantAPI } from '../../../API/Applicant';
import { propertiesAPI } from '../../../API/properties';
import { landlordAPI } from '../../../API/Landlord';

const Home = () => {
  const [leadsCount, setLeadsCount] = useState(0);
  const [applicantsCount, setApplicantsCount] = useState(0);
  const [propertiesCount, setPropertiesCount] = useState(0);
  const [landlordsCount, setLandlordsCount] = useState(0);
  const [allProperties, setAllProperties] = useState([]);
  const [allLandlords, setAllLandlords] = useState(0);

  useEffect(() => {
    const fetchAllData = async () => {
      const [leadsCount, applicantsCount, allProperties, allLandlords] = await Promise.all([
        leadsAPI.getLeadsCount(),
        applicantAPI.getApplicantCount(),
        propertiesAPI.getAllProperties({ project: { _id: 0, status: 1 } }),
        landlordAPI.getAllReducedDataLandlords(),
      ]);

      setLeadsCount(leadsCount?.data);
      setApplicantsCount(applicantsCount?.data);
      setPropertiesCount(allProperties?.data?.length);
      setLandlordsCount(allLandlords?.data?.length);
      setAllProperties(allProperties?.data);
      setAllLandlords(allLandlords?.data);
    };

    fetchAllData();
  }, []);

  return (
    <div>
      <SiteHeading
        Heading="Dashboard"
        menu={breadcrumb?.homePage}
      />
      <StatisticsComponent {...{ landlordsCount, propertiesCount, leadsCount, applicantsCount }} />
      <ChartsComponent {...{ allProperties, allLandlords }} />
    </div>
  );
};

export default Home;
