import React from 'react';
import { ToastNotification } from '@carbon/react';

export default function Toaster(props) {
  const { title, message, type, timeout, subTitle, handleCloseToaster } = props;

  // notification kind(type) option
  // error , info , info-square , success , warning , warning-alt
  return (
    <ToastNotification
      onClose={handleCloseToaster}
      caption={message}
      icondescription="Close Notification"
      subtitle={subTitle ? <span kind={type}>{subTitle}</span> : ''}
      kind={type || 'info'}
      timeout={(timeout || 5) * 1000}
      closeonescape="true"
      placement="left"
      title={title || 'Notification title'}
    />
  );
}
