import { Breadcrumb, BreadcrumbItem } from '@carbon/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const SiteHeading = ({ Heading, children, menu }) => {
  const navigate = useNavigate();
  const handleRedirect = (list) => {
    if (list?.redirect) {
      navigate(list?.path);
    }
    return null;
  };

  return (
    <div className="site-heading">
      <Breadcrumb noTrailingSlash>
        {menu &&
          menu?.length > 0 &&
          menu.map((list, index) => {
            return index === menu?.length - 1 ? (
              <BreadcrumbItem
                key={index}
                className="cursor-pointer"
                isCurrentPage
              >
                {list?.title || ''}
              </BreadcrumbItem>
            ) : (
              <BreadcrumbItem
                key={index}
                className="cursor-pointer"
                onClick={() => handleRedirect(list)}
              >
                {list?.title || ''}
              </BreadcrumbItem>
            );
          })}
      </Breadcrumb>
      <div className="page-title-wrap">
        <h6 className="page-title">{Heading}</h6>
        {children}
      </div>
    </div>
  );
};

export default SiteHeading;
