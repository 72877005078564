import React, { useState } from 'react';
import { landlordAPI } from '../../../API/Landlord';
import { dateFormatTable } from '../../../Utils/constants';
import DataTableControl from '../../Common/DataTable';
import SiteHeading from '../../Common/SiteHeading';
import Toaster from '../../Common/Toaster';
import moment from 'moment/moment';
import ButtonControl from '../../Common/Button';
import DropdownControl from '../../Common/Dropdown/dropdown';
import { allImage } from '../../../Utils/allImageImport';
import { breadcrumb } from '../../../Utils/breadcrumbMenus';
import { useNavigate } from 'react-router-dom';
import SearchFilter from '../../Common/SearchFilter';
import RangePicker from '../../Common/DateRangePicker';
import { ISOStringDate } from '../../../Utils/common';

export default function LandLord() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({});
  const [filterValue, setFilterValue] = useState({});
  const [toaster, setToaster] = useState(false);
  const [error, setError] = useState({
    title: '',
    message: '',
    timeOut: '',
    type: '',
    subTitle: '',
  });

  const subscription = [
    {
      id: '1',
      label: 'All',
      value: null,
    },
    {
      id: '1',
      label: 'active',
      value: 'active',
    },
    {
      id: '2',
      label: 'expired',
      value: 'expired',
    },
    {
      id: '4',
      label: 'none',
      value: 'none',
    },
  ];

  const getTableData = async (filter) => {
    setLoading(true);
    filter.$page = filter.$page || 1;
    filter.$limit = filter.$limit || 10;
    filter.filter = filter.filter || {};

    if(!(filter.filter.createdAt)) {
      filter.filter.createdAt = {
        $gte: ISOStringDate(moment().subtract(30, 'days')),
        $lt: ISOStringDate(moment()),
      };
    }

    const res = await landlordAPI?.getAllLandlords(filter);
    if (res || !res) setLoading(false);
    if (res && res?.status === true) {
      toaster && setToaster(false);
      return res?.data;
    } else {
      setToaster(true);
      setError({
        ...error,
        title: 'API Fail',
        message: res?.data?.message || 'Something went to wrong!',
        type: 'error',
      });
      return [];
    }
  };

  const handleCloseToaster = () => {
    setToaster(false);
  };

  const handleOpenDetailsPage = (id) => {
    navigate(`/landlords/landlords-detail/${id}`, { state: id });
  };

  const headingData = [
    {
      key: 'id',
      sortable: false,
      header: '',
      render: (value) => {
        return (
          <div className="table-action">
            <span
              onClick={() => handleOpenDetailsPage(value)}
              className="cursor-pointer"
            >
              <img src={allImage?.view} alt="" />
            </span>
          </div>
        );
      },
    },
    {
      key: 'first_name',
      sortable: true,
      header: 'Name',
    },
    {
      key: 'email',
      sortable: true,
      header: 'Email',
    },
    {
      key: 'phone',
      sortable: false,
      header: 'Phone No.',
    },
    {
      key: 'createdAt',
      sortable: false,
      header: 'Signup Date',
      render: (value) => {
        return (
          <span> {(value && moment(value).format(dateFormatTable)) || ''}</span>
        );
      },
    },
    {
      key: 'no_of_property',
      sortable: false,
      header: 'No of Property',
    },
    {
      key: 'subscription_status',
      sortable: false,
      header: 'Subscription',
      render: (value) => {
        return (
          <span className="status green">{value}</span>
        );
      },
    },
  ];

  const handleChange = (e) => {
    setFilterValue(prev => ({
      ...prev,
      ...e,
    }));
  };

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escaping special characters
  }

  function filterToRegexPattern(filter_value) {
    const escapedFilter = escapeRegExp(filter_value);
    const parts = escapedFilter.split(/\s+/); // Split full name into parts
    const regexParts = parts.map(part => `(?=.*${part})`).join('|');
    return `^${regexParts}.*$`;
  }

  const handleAddFilter = () => {
    let filterData = {};
    const value = Object.keys(filterValue).map((keyName) => {
      if (filterValue[keyName]) {
        filterData[keyName] = filterValue[keyName];
      }
      if (keyName === "filter_value" && filterValue[keyName]) {
        filterData[keyName] = filterToRegexPattern(filterValue[keyName]);
      }
    });
    setFilter(filterData);
  };

  return (
    <>
      <SiteHeading
        Heading="Landlords"
        menu={breadcrumb?.landlordListPage}
      ></SiteHeading>
      <div className="property-wrap">
        {toaster && (
          <Toaster
            title={error?.title}
            message={error?.message}
            type={error?.type}
            handleCloseToaster={handleCloseToaster}
          />
        )}
        <SearchFilter
          handleChange={(e) => handleChange({ filter_value: e })}
          handleSearch={handleAddFilter}
          value={filterValue?.filter_value || ''}
        />
        <div className="filter-wrap">
          <RangePicker
            value={{
              startDate: filterValue?.createdAt?.['$gte'],
              endDate: filterValue?.createdAt?.['$lt'],
            }}
            handleChange={(e) =>
              handleChange({
                createdAt: {
                  $gte: ISOStringDate(e?.startDate),
                  $lt: ISOStringDate(e?.endDate),
                },
              })
            }
            labelText="Sign up date"
          />
          <DropdownControl
            id="sub"
            label=""
            titleText="Subscription"
            onChange={(e) =>
              handleChange({ subscription_status: e?.selectedItem?.value })
            }
            items={subscription}
          />
          <ButtonControl onClick={handleAddFilter}>Filter</ButtonControl>
        </div>
        <div className="table-nowrap">
          <DataTableControl
            filter={filter}
            columns={headingData}
            getData={getTableData}
            sortable
            showPagination
            loading={loading}
          />
        </div>
      </div>
    </>
  );
}
