import moment from 'moment/moment';
import React, { useEffect, useMemo, useState } from 'react';
import { propertiesAPI } from '../../API/properties';
import { breadcrumb } from '../../Utils/breadcrumbMenus';
import { dynamicId, ISOStringDate } from '../../Utils/common';
import { dateFormatTable } from '../../Utils/constants';
import ButtonControl from '../Common/Button';
import DataTableControl from '../Common/DataTable';
import RangePicker from '../Common/DateRangePicker';
import DropdownControl from '../Common/Dropdown/dropdown';
import Modal from '../Common/Modal';
import RangeSliderControl from '../Common/RangeSlider';
import SearchFilter from '../Common/SearchFilter';
import SiteHeading from '../Common/SiteHeading';
import Toaster from '../Common/Toaster';
import { landlordAPI } from '../../API/Landlord';

const UnListPropertyComponents = () => {
  const [requestModel, setRequestModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allLandlords, setAllLandlords] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [filter, setFilter] = useState({});
  const [filterValue, setFilterValue] = useState({});
  const [toaster, setToaster] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [unListPropertyId, setUnListPropertyId] = useState(null);
  const [error, setError] = useState({
    title: '',
    message: '',
    timeOut: '',
    type: '',
    subTitle: '',
  });

  useEffect(() => {
    if (isSubmit) {
      handleAddFilter();
    }
  }, [isSubmit]);

  /* Only fetched when refresh 
     for performance optimization */
  useMemo(async () => {
    const res = await landlordAPI?.getAllReducedDataLandlords();
    setAllLandlords(res?.data);
  }, []);

  const leaseDurationMenu = [
    {
      id: '6',
      label: 'All',
      value: null,
    },
    {
      id: '1',
      label: 'Month to Month',
      value: 'Month to Month',
    },
    {
      id: '2',
      label: '6 Month',
      value: '6 Month',
    },
    {
      id: '3',
      label: '1 Year',
      value: '1 Year',
    },
    {
      id: '4',
      label: '2 Years',
      value: '2 Years',
    },
  ];

  const getTableData = async (filter) => {
    setLoading(true);
    setIsSubmit(false);
    filter.$page = filter.$page || 1;
    filter.$limit = filter.$limit || 10;
    if(!(filter.filter.subscription)){
      filter.filter["subscription"]={};
      filter.filter["subscription"]["current_period_end"] = {
        $gte: ISOStringDate(moment().subtract(30, 'days')),
        $lt: ISOStringDate(moment()),
      };
    }
    if(filter.filter?.subscription?.current_period_end) {
      const getCurrentPeriod = filter.filter.subscription.current_period_end;
      delete filter.filter.subscription;
      filter.filter["subscription.current_period_end"] = getCurrentPeriod;
    }
    filter.filter = {
      ...filter.filter,
      status: 'Un List Requested',
    };

    const res = await propertiesAPI?.getAllFileteredProperties(filter);
    if (res || !res) setLoading(false);
    if (res && res?.status === true) {
      toaster && setToaster(false);
      return res?.data;
    } else {
      setToaster(true);
      setError({
        ...error,
        title: 'API Fail',
        message: res?.data?.message || 'Something went to wrong!',
        type: 'error',
      });
      return [];
    }
  };

  const handleCloseToaster = () => {
    setToaster(false);
  };

  const handleChange = (e) => {
    setFilterValue({
      ...filterValue,
      ...e,
    });
  };

  const handleChangeRange = (e) => {
    setFilterValue({
      ...filterValue,
      ...e,
    });
  };
  
  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escaping special characters
  }

  function filterToRegexPattern(filter_value) {
    const escapedFilter = escapeRegExp(filter_value);
    const parts = escapedFilter.split(/\s+/); // Split full name into parts
    const regexParts = parts.map(part => `(?=.*${part})`).join('|');
    return `^${regexParts}.*$`;
  }

  const handleAddFilter = () => {
    let filterData = {};
    const value = Object.keys(filterValue).map((keyName) => {
      if (keyName === 'subscription') {
        if (filterValue?.subscription?.current_period_end?.['$gte']) {
          filterData[keyName] = filterValue[keyName];
        }
      } else {
        if (filterValue[keyName]) {
          filterData[keyName] = filterValue[keyName];
        }
      }
    });

    const valueToBeFiltered = filterToRegexPattern(searchValue);
    const pattern = new RegExp(valueToBeFiltered, 'i');
    const filteredLanlordsIds = allLandlords?.filter((data) => {
      return (
        pattern.test(data.email)
        || pattern.test(data.first_name)
        || pattern.test(data.last_name)
      );
    }).map(data => data._id);

    filterData['landlord'] = {
      $in: filteredLanlordsIds
    }

    setFilter(filterData);
    return value;
  };

  const handleUnListModal = (id) => {
    handleRequestModal();
    setUnListPropertyId(id);
  };

  const headingData = [
    {
      key: 'id',
      sortable: false,
      header: '',
      render: (value) => {
        return (
          <div className="table-action">
            <ButtonControl
              className="un-list-btn"
              onClick={() => handleUnListModal(value)}
            >
              Unlist
            </ButtonControl>
          </div>
        );
      },
    },
    {
      key: 'landlord',
      sortable: true,
      header: 'Landlord Name',
      render: (value) => (
        <span> {value && `${value.first_name} ${value.last_name}`}</span>
      ),
    },
    {
      key: 'address',
      sortable: true,
      header: 'Address',
    },
    {
      key: 'subscription',
      sortable: true,
      header: 'Expired on',
      render: (value) => {
        return (
          <span>
            {' '}
            {(value?.current_period_end &&
              moment(value?.current_period_end).format(dateFormatTable)) ||
              ''}
          </span>
        );
      },
    },
    {
      key: 'lease_duration',
      header: 'Lease terms',
      sortable: true,
      render: (value) => <span> {`${value}`}</span>,
    },
    {
      key: 'square_footage',
      sortable: true,
      header: 'Square footage',
      render: (value) => {
        return (
          <span>
            {' '}
            {value ? `${value?.toString()?.replace('sqft', '')} sqft` : ''}
          </span>
        );
      },
    },
  ];

  const requestModalClose = () => {
    setRequestModal(false);
  };

  const handleRequestModal = () => {
    setRequestModal(true);
  };

  const handleUnListPropertyAPI = async () => {
    requestModalClose();
    if (unListPropertyId) {
      setLoading(true);
      const payload = {
        status: 'Un listed',
      };
      const res = await propertiesAPI?.updatePropertyStatus(
        unListPropertyId,
        payload
      );
      if (res && res?.status === true) {
        handleRefresh();
        setLoading(false);
        setToaster(true);
        setError({
          title: 'Success',
          message: res?.data?.message || 'Something went to wrong!',
          type: 'success',
        });
      } else {
        setLoading(false);
        setError({
          title: 'API Fail',
          message: res?.data?.message || 'Something went to wrong!',
          type: 'error',
        });
        setToaster(true);
      }
    }
  };

  const handleRefresh = () => {
    setFilter({});
    setFilterValue({});
  };

  return (
    <>
      <SiteHeading
        Heading="UnList Property"
        menu={breadcrumb?.unListPropertiesListPage}
      ></SiteHeading>
      <div className="property-wrap">
        {toaster && (
          <Toaster
            title={error?.title}
            message={error?.message}
            type={error?.type}
            handleCloseToaster={handleCloseToaster}
          />
        )}
        <SearchFilter
          handleChange={(e) => setSearchValue(e)}
          handleSearch={() => setIsSubmit(true)}
          value={searchValue}
        />
        <div className="filter-wrap">
          <RangePicker
            value={{
              startDate: filterValue?.subscription?.current_period_end?.['$gte'],
              endDate: filterValue?.subscription?.current_period_end?.['$lt'],
            }}
            handleChange={(e) =>
              handleChange({
                subscription: {
                  current_period_end: {
                    $gte: ISOStringDate(e?.startDate),
                    $lt: ISOStringDate(e?.endDate),
                  },
                },
              })
            }
          />
          <RangeSliderControl
            isSubmit={isSubmit}
            title="Sqft size"
            step={1000}
            min={1}
            max={10000}
            value={{
              min: filterValue?.square_footage?.$gte,
              max: filterValue?.square_footage?.$lt,
            }}
            onChange={(e) =>
              e?.min || e?.max
                ? handleChangeRange({
                  square_footage: {
                    $gte: e?.min,
                    $lt: e?.max,
                  },
                })
                : handleChangeRange({ square_footage: null })
            }
          />
          <DropdownControl
            titleText="Lease terms"
            className="lease-dropdown"
            items={leaseDurationMenu}
            onChange={(e) =>
              e?.selectedItem?.value
                ? handleChange({ lease_duration: e?.selectedItem?.value })
                : handleChange({
                  lease_duration: null,
                })
            }
            id={dynamicId()}
            label=""
          />
          <ButtonControl onClick={() => setIsSubmit(true)}>
            Filter
          </ButtonControl>
        </div>
        <DataTableControl
          filter={filter}
          columns={headingData}
          getData={getTableData}
          sortable
          showPagination
          loading={loading}
        />
      </div>
      <Modal
        className="unlist-modal"
        open={requestModel}
        onClose={() => requestModalClose()}
        title="Are you sure"
      >
        <p>your property will be unlisted within 24 hours</p>
        <div className="unlisted-btn">
          <ButtonControl kind="secondary" onClick={() => requestModalClose()}>
            Cancel
          </ButtonControl>
          <ButtonControl kind="danger" onClick={handleUnListPropertyAPI}>
            Unlist
          </ButtonControl>
        </div>
      </Modal>
    </>
  );
};

export default UnListPropertyComponents;
