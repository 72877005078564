import React from 'react';
import { Button } from '@carbon/react';

export default function ButtonControl({
  fullWidth,
  className,
  children,
  ...rest
}) {
  return (
    <Button className={`${className} ${fullWidth ? 'w-100' : ''}`} {...rest}>
      {children}
    </Button>
  );
}
