import React, { useEffect, useMemo, useState } from 'react';
import { propertiesAPI } from '../../../API/properties';
import { landlordAPI } from '../../../API/Landlord';
import DataTableControl from '../../Common/DataTable';
import SiteHeading from '../../Common/SiteHeading';
import Toaster from '../../Common/Toaster';
import { dateFormatTable } from '../../../Utils/constants';
import moment from 'moment/moment';
import ButtonControl from '../../Common/Button';
import DropdownControl from '../../Common/Dropdown/dropdown';
import { breadcrumb } from '../../../Utils/breadcrumbMenus';
import { dynamicId } from '../../../Utils/common';
import { allImage } from '../../../Utils/allImageImport';
import { useNavigate } from 'react-router-dom';
import CheckboxControl from '../../Common/Checkbox';
import SearchFilter from '../../Common/SearchFilter';
import RangeSliderControl from '../../Common/RangeSlider';

const Properties = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [allLandlords, setAllLandlords] = useState([]);
  const [filter, setFilter] = useState({
    square_footage: {
      $gte: 1,
      $lt: 1000,
    },
    monthly_rent: {
      $gte: 1,
      $lt: 1000,
    }
  });
  const [filterValue, setFilterValue] = useState({
    square_footage: {
      $gte: 1,
      $lt: 1000
    },
    monthly_rent: {
      $gte: 1,
      $lt: 1000
    }
  });
  const [searchValue, setSearchValue] = useState('');
  const [toaster, setToaster] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState({
    title: '',
    message: '',
    timeOut: '',
    type: '',
    subTitle: '',
  });

  useEffect(() => {
    if (isSubmit) {
      handleAddFilter();
    }
  }, [isSubmit]);

  /* Only fetched when refresh 
     for performance optimization */
  useMemo(async () => {
    const res = await landlordAPI?.getAllReducedDataLandlords();
    setAllLandlords(res?.data);
  }, []);

  const bedrooms = [
    {
      id: '6',
      label: 'All',
      value: null,
    },
    {
      id: '1',
      label: '1-2',
      value: '1-2',
    },
    {
      id: '2',
      label: '2-4',
      value: '2-4',
    },
    {
      id: '3',
      label: '4-7',
      value: '4-7',
    },
    {
      id: '4',
      label: '7-10',
      value: '7-10',
    },
    {
      id: '5',
      label: '10+',
      value: '10-10000000',
    },
  ];

  const getTableData = async (filter) => {
    setLoading(true);
    setIsSubmit(false);
    filter.$page = filter.$page || 1;
    filter.$limit = filter.$limit || 10;

    const res = await propertiesAPI?.getAllFileteredProperties(filter);
    if (res || !res) setLoading(false);
    if (res && res?.status === true) {
      toaster && setToaster(false);
      return res?.data;
    } else {
      setToaster(true);
      setError({
        ...error,
        title: 'API Fail',
        message: res?.data?.message || 'Something went to wrong!',
        type: 'error',
      });
      return [];
    }
  };

  const handleCloseToaster = () => {
    setToaster(false);
  };

  const handleOpenDetailsPage = (id) => {
    navigate(`/properties/property-detail/${id}`, { state: id });
  };

  const handleOpenEditPage = (id) => {
    navigate(`/properties/edit-property/${id}`, { state: id });
  };

  const handleChange = (e) => {
    setFilterValue(prev => ({
      ...prev,
      ...e,
    }));
  };

  const handleChangeRange = (e) => {
    setFilterValue({
      ...filterValue,
      ...e,
    });
  };

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escaping special characters
  }

  function filterToRegexPattern(filter_value) {
    const escapedFilter = escapeRegExp(filter_value);
    const parts = escapedFilter.split(/\s+/); // Split full name into parts
    const regexParts = parts.map(part => `(?=.*${part})`).join('|');
    return `^${regexParts}.*$`;
  }

  const handleAddFilter = () => {
    let filterData = {};
    const value = Object.keys(filterValue).map((keyName) => {
      if (filterValue[keyName]) {
        filterData[keyName] = filterValue[keyName];
      }
    });

    const valueToBeFiltered = filterToRegexPattern(searchValue);
    const pattern = new RegExp(valueToBeFiltered, 'i');
    const filteredLanlordsIds = allLandlords?.filter((data) => {
      return (
        pattern.test(data.email)
        || pattern.test(data.first_name)
        || pattern.test(data.last_name)
      );
    }).map(data => data._id);

    filterData['landlord'] = {
      $in: filteredLanlordsIds
    }

    setFilter(filterData);
    return value;
  };

  const headingData = [
    {
      key: 'id',
      sortable: false,
      header: '',
      render: (value) => {
        return (
          <div className="table-action">
            <span
              onClick={() => handleOpenDetailsPage(value)}
              className="cursor-pointer"
            >
              <img src={allImage?.view} alt="" />
            </span>
            <span
              onClick={() => handleOpenEditPage(value)}
              className="cursor-pointer"
            >
              <img src={allImage?.edit} alt="" />
            </span>
          </div>
        );
      },
    },
    {
      key: 'landlord',
      sortable: true,
      header: 'Landlord Name',
      render: (value) => (
        <span> {value && `${value.first_name} ${value.last_name}`}</span>
      ),
    },
    {
      key: 'address',
      sortable: true,
      header: 'address',
    },
    {
      key: 'date_available',
      sortable: false,
      header: 'Available Date',
      render: (value) => {
        return (
          <span> {(value && moment(value).format(dateFormatTable)) || ''}</span>
        );
      },
    },
    {
      key: 'monthly_rent',
      header: 'Price',
      sortable: true,
      render: (value) => <span> {`$${value}`}</span>,
    },
    {
      key: 'status',
      sortable: false,
      header: 'Status',
      render: (value) => {
        return (
          <span
            className={`category ${value === 'Draft' ? 'draft' : (value === 'Submitted for Listing' ? 'review' : 'listed')}`}
            style={{ position: 'inherit', cursor: 'initial' }}>
            {value}
          </span>
        );
      },
    },
    {
      key: 'square_footage',
      sortable: false,
      header: 'Size',
    },
    {
      key: 'zumper',
      sortable: false,
      header: 'Zumper',
      render: (value) => {
        return <CheckboxControl labelText="" defaultChecked={value} id="Zumper" disabled />;
      },
    },
    {
      key: 'zillow',
      sortable: false,
      header: 'Zillow',
      render: (value) => {
        return (
          <CheckboxControl labelText="" defaultChecked={value} id="Zillow" disabled />
        );
      },
    },
    {
      key: 'apartments',
      sortable: false,
      header: 'Apartments',
      render: (value) => {
        return (
          <CheckboxControl
            disabled
            defaultChecked={value}
            labelText=""
            id="Apartments"
          />
        );
      },
    },
  ];

  return (
    <>
      <SiteHeading
        Heading="Properties"
        menu={breadcrumb?.propertiesListPage}
      ></SiteHeading>
      <div className="property-wrap">
        {toaster && (
          <Toaster
            title={error?.title}
            message={error?.message}
            type={error?.type}
            handleCloseToaster={handleCloseToaster}
          />
        )}
        <SearchFilter
          handleChange={(e) => setSearchValue(e)}
          handleSearch={() => setIsSubmit(true)}
          value={searchValue}
        />
        <div className="filter-wrap">
          <RangeSliderControl
            isSubmit={isSubmit}
            title="Price"
            step={1000}
            min={1}
            max={10000}
            value={{
              min: filterValue?.monthly_rent?.$gte,
              max: filterValue?.monthly_rent?.$lt,
            }}
            onChange={(e) =>
              e?.min || e?.max
                ? handleChangeRange({
                  monthly_rent: {
                    $gte: e?.min,
                    $lt: e?.max,
                  },
                })
                : handleChangeRange({ monthly_rent: null })
            }
          />
          <RangeSliderControl
            isSubmit={isSubmit}
            title="Size"
            step={1000}
            min={1}
            max={10000}
            value={{
              min: filterValue?.square_footage?.$gte,
              max: filterValue?.square_footage?.$lt,
            }}
            onChange={(e) =>
              e?.min || e?.max
                ? handleChangeRange({
                  square_footage: {
                    $gte: e?.min,
                    $lt: e?.max,
                  },
                })
                : handleChangeRange({ square_footage: null })
            }
          />
          <DropdownControl
            titleText="Bedrooms"
            className="bedroom-dropdown"
            items={bedrooms}
            onChange={(e) =>
              e?.selectedItem?.value
                ? handleChange({
                  bedrooms: {
                    $gte:
                      (e?.selectedItem?.value &&
                        e?.selectedItem?.value?.split('-')[0]) ||
                      null,
                    $lt:
                      (e?.selectedItem?.value &&
                        e?.selectedItem?.value?.split('-')[1]) ||
                      null,
                  },
                })
                : handleChange({
                  bedrooms: null,
                })
            }
            id={dynamicId()}
            label=""
          />
          <ButtonControl onClick={() => setIsSubmit(true)}>
            Filter
          </ButtonControl>
        </div>
        <DataTableControl
          filter={filter}
          columns={headingData}
          getData={getTableData}
          sortable
          showPagination
          loading={loading}
        />
      </div>
    </>
  );
};

export default Properties;
