import { get } from '../base';
const endPoint = 'admin/landlord';

const getSingleLandlord = async (id) => {
  const response = await get(`${endPoint}/${id}`);
  return response;
};

const getAllLandlords = async (params) => {
  const response = await get(`${endPoint}`, params);
  return response;
};

const getAllReducedDataLandlords = async () => {
  const response = await get(`${endPoint}/all`);
  return response;
};

export const landlordAPI = {
  getAllLandlords,
  getSingleLandlord,
  getAllReducedDataLandlords,
};
