import React from 'react';
import { Loading as Loader } from '@carbon/react';

export default function SectionLoader(props) {
  return (
    <div className="loader-bg">
      <Loader
        description="Loading..."
        withOverlay={true}
        small={true}
        className="section-loader"
        {...props}
      />
    </div>
  );
}
