import React from 'react';
import { Tooltip } from '@carbon/react';

export default function TooltipControl({
  children,
  ...rest
}) {
  return (
    <Tooltip {...rest}>
      <button className="sb-tooltip-trigger" type="button">
        {children}
      </button>
    </Tooltip>
  );
}
