import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { propertiesAPI } from '../../../API/properties';
import { breadcrumb } from '../../../Utils/breadcrumbMenus';
import ButtonControl from '../../Common/Button';
import MainLoader from '../../Common/Loader/MainLoader';
import SiteHeading from '../../Common/SiteHeading';
import Toaster from '../../Common/Toaster';
import PropertyDetailComponents from '../../Properties/propertyDetail';

let customMenu = [];
const PropertyDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state, pathname } = location;
  const [details, setDetails] = useState({});
  const [toaster, setToaster] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [toastError, setToastError] = useState({});

  useEffect(() => {
    if (details?.address) {
      if (!customMenu.some(({ title }) => title === details?.address)) {
        customMenu = [];
        customMenu = breadcrumb.propertiesDetailsPage.slice();
        customMenu.push({
          title: details?.address,
          path: '/properties/name',
          redirect: false,
        });
      }
    }
  }, [details]);

  useEffect(() => {
    if (state || pathname?.split('/')[3]) {
      handleGetPropertyValue(state || pathname?.split('/')[3]);
    }
  }, [state, refresh]);

  const handleGetPropertyValue = async (id) => {
    setLoading(true);
    const res = await propertiesAPI?.getSingleProperty(id);
    if (res && res?.status === true) {
      setDetails(res?.data || {});
      toaster && setToaster(false);
      setLoading(false);
    } else {
      setToastError({
        title: 'API Fail',
        message: res?.data || 'Something went to wrong!',
        type: 'error',
      });
      setToaster(true);
      setLoading(false);
    }
  };

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const handleRedirectEditPage = () => {
    navigate(`/properties/edit-property/${details?._id}`, {
      state: details?._id,
    });
  };

  return (
    <>
      {loading && <MainLoader />}
      {toaster && (
        <Toaster
          title={toastError?.title}
          type={toastError?.type}
          message={toastError?.message}
          handleCloseToaster={() => setToaster(false)}
        />
      )}
      <SiteHeading Heading={details?.address} menu={customMenu}>
        <ButtonControl onClick={handleRedirectEditPage}>Edit</ButtonControl>
      </SiteHeading>
      <PropertyDetailComponents {...{ details, handleRefresh }} />
    </>
  );
};

export default PropertyDetail;
