import homeMenu from '../Assets/Images/homeMenu.svg';
import invoiceMenu from '../Assets/Images/invoiceMenu.svg';
import propertiesMenu from '../Assets/Images/propertiesMenu.svg';
import logo from '../Assets/Images/logo.svg';
import menuIcon from '../Assets/Images/menuIcon.svg';
import facebookIcon from '../Assets/Images/facebook.png';
import uploadIcon from '../Assets/Images/imageUploadIcon.svg';
import badRoom from '../Assets/Images/badroom.svg';
import bathRoom from '../Assets/Images/bathroom.svg';
import edit from '../Assets/Images/edit.svg';
import view from '../Assets/Images/view.svg';
import propertiesImage from '../Assets/Images/properties-img1.jpg';
import landLords from '../Assets/Images/landlordsIcon.svg';
import managerIcon from '../Assets/Images/managerIcon.svg';
import propertiesReview from '../Assets/Images/propertiesReview.svg';
import subscription from '../Assets/Images/subscription.svg';
import unlistedProperty from '../Assets/Images/unlistedProperty.svg';
import downloadIcon from '../Assets/Images/download.svg';
import rightIcon from '../Assets/Images/arrow-right.svg';
import propertyDetailImage from '../Assets/Images/property-detail-img.jpg';
import noImageAvailableIcon from '../Assets/Images/noImageAvailable.png';
import sqftIcon from '../Assets/Images/sqft.svg';
import dateIcon from '../Assets/Images/date.svg';
import acIcon from '../Assets/Images/ac.svg';
import balconyIcon from '../Assets/Images/balcony.svg';
import furnishedIcon from '../Assets/Images/furnished.svg';
import gasIcon from '../Assets/Images/gas.svg';
import internetIcon from '../Assets/Images/internet.svg';
import closeIcon from '../Assets/Images/close.svg';
import balconyAndDeckIcon from '../Assets/Images/balconyAndDeck.svg';
import cableIcon from '../Assets/Images/cable.svg';
import disabledAccessIcon from '../Assets/Images/disabledAccess.svg';
import dishwasherIcon from '../Assets/Images/dishwasher.svg';
import dryerIcon from '../Assets/Images/dryer.svg';
import electricIcon from '../Assets/Images/electric.svg';
import garageParkingIcon from '../Assets/Images/garageParking.svg';
import hardwoodFloorsIcon from '../Assets/Images/hardwoodFloors.svg';
import heatIcon from '../Assets/Images/heat.svg';
import hotWaterIcon from '../Assets/Images/hotWater.svg';
import nounSewerIcon from '../Assets/Images/nounSewer.svg';
import offStreetParkingIcon from '../Assets/Images/offStreetParking.svg';
import poolBicycleStorageIcon from '../Assets/Images/poolBicycleStorage.svg';
import satelliteIcon from '../Assets/Images/satellite.svg';
import securityAlarmIcon from '../Assets/Images/securityAlarm.svg';
import sewerIcon from '../Assets/Images/sewer.svg';
import telephoneIcon from '../Assets/Images/telephone.svg';
import trashIcon from '../Assets/Images/trash.svg';
import washerIcon from '../Assets/Images/washer.svg';
import waterIcon from '../Assets/Images/water.svg';
import wifiIcon from '../Assets/Images/wifi.svg';
import leadsIcon from '../Assets/Images/leadsIcon.svg';
import applicantsIcon from '../Assets/Images/applicantsIcon.svg';

export const allImage = {
  homeMenu,
  invoiceMenu,
  propertiesMenu,
  logo,
  menuIcon,
  facebookIcon,
  uploadIcon,
  badRoom,
  bathRoom,
  edit,
  view,
  propertiesImage,
  landLords,
  managerIcon,
  propertiesReview,
  subscription,
  unlistedProperty,
  downloadIcon,
  rightIcon,
  propertyDetailImage,
  sqftIcon,
  dateIcon,
  acIcon,
  balconyIcon,
  furnishedIcon,
  gasIcon,
  internetIcon,
  closeIcon,
  balconyAndDeckIcon,
  cableIcon,
  disabledAccessIcon,
  dishwasherIcon,
  dryerIcon,
  electricIcon,
  garageParkingIcon,
  hardwoodFloorsIcon,
  heatIcon,
  hotWaterIcon,
  nounSewerIcon,
  offStreetParkingIcon,
  poolBicycleStorageIcon,
  satelliteIcon,
  securityAlarmIcon,
  sewerIcon,
  telephoneIcon,
  trashIcon,
  washerIcon,
  waterIcon,
  wifiIcon,
  noImageAvailableIcon,
  leadsIcon,
  applicantsIcon,
};
