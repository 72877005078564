import { get, post } from '../base';
const endPoint = 'admin/';

const login = async (body) => {
  const response = await post(`${endPoint}login`, body);
  return response;
};

const signup = async (body) => {
  const response = await post(`${endPoint}signup`, body);
  return response;
};

const logout = async (id) => {
  const response = await get(`${endPoint}logout/${id}`);
  return response;
};

export const authAPI = {
  login,
  signup,
  logout,
};
