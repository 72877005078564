import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { propertiesAPI } from '../../API/properties';
import { allImage } from '../../Utils/allImageImport';
import { dynamicId, getBase64 } from '../../Utils/common';
import { momentDateFormat } from '../../Utils/constants';
import { number } from '../../Utils/Regex';
import ButtonControl from '../Common/Button';
import CheckboxControl from '../Common/Checkbox';
import DatePickerControl from '../Common/DatePicker';
import DropdownComboBoxControl from '../Common/Dropdown/comboBox';
import MultiSelectDropdownControl from '../Common/Dropdown/multiSelectDropdown';
import MainLoader from '../Common/Loader/MainLoader';
import TextAreaControl from '../Common/TextArea';
import TextFieldControl from '../Common/TextField';
import Toaster from '../Common/Toaster';

export default function EditPropertyComponent({ details }) {
  const image = 'image';
  const navigate = useNavigate();
  const [addPropertyForm, setAddPropertyForm] = useState({
    utilities: [],
    amenities: [],
  });
  const [addPropertyFormError, setAddPropertyFormError] = useState({});
  const [imageList, setImageList] = useState([]);
  const [imageBase64List, setImageBase64List] = useState([]);
  const [imageDeleteIds, setImageDeleteIds] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [dropdownValueList, setDropdownValueList] = useState({});
  const [toastError, setToastError] = useState({});
  const [toaster, setToaster] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (details && Object.keys(details)?.length) {
      const cloneAddForm = { ...addPropertyForm };
      cloneAddForm.address = details?.address;
      cloneAddForm.totalBedroom = details?.bedrooms;
      cloneAddForm.totalBathroom = details?.bathrooms;
      cloneAddForm.monthlyRent = details?.monthly_rent;
      cloneAddForm.securityDeposit = details?.security_deposit;
      cloneAddForm.squareFootage = details?.square_footage;
      cloneAddForm.notes = details?.notes;
      cloneAddForm.status = details?.status;
      cloneAddForm.dateAvailability = details?.date_available;
      cloneAddForm.leaseDuration = details?.lease_duration;
      cloneAddForm.petPolicy = details?.pet_policy;
      let value = [];
      if (details?.amenities) {
        const dbData = details?.amenities[0]?.split(',');
        dropdownValueList?.amenities?.map((data) => {
          if (dbData?.includes(data?.label)) {
            value.push(data);
          }
          return value;
        });
      }
      cloneAddForm.amenities = value || [];
      value = [];
      if (details?.utilities) {
        const dbData = details?.utilities[0]?.split(',');
        dropdownValueList?.utilities?.map((data) => {
          if (dbData?.includes(data?.label)) {
            value.push(data);
          }
          return value;
        });
      }
      cloneAddForm.utilities = value || [];
      value = [];
      if (details?.listings) {
        value = details?.listings[0]?.split(',');
        const obj = {};
        value = value?.map((data) => {
          if(obj[data] === undefined){
            obj[data]=1;
            return data;
          }
        });
      }
      cloneAddForm.listings = value || [];
      value = [];
      cloneAddForm.description = details?.description;
      if (details?.images && details?.images?.length) {
        value = [];
        details?.images?.map((list) => {
          return value.push(list?.thumbnail);
        });
        setImageList(details?.images || []);
        setImageBase64List(value || []);
      }
      setAddPropertyForm(cloneAddForm);
    }
  }, [details, dropdownValueList]);

  useEffect(() => {
    if (refresh === image) {
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    getStaticDropdownList();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e?.target || e;
    setAddPropertyForm({
      ...addPropertyForm,
      [name]: value,
    });
  };

  const handleChangeImage = (e) => {
    const { files } = e?.target || e;
    if (files && files?.length) {
      setImageList([...imageList, ...files]);
      const URLValue = [];
      [...files]?.map(async (image) => {
        const urls = await getBase64(image);
        URLValue.push(urls);
        setImageBase64List([...imageBase64List, ...URLValue]);
      });
      setRefresh('image');
    }
  };

  const handleRemoveImage = (index) => {
    if (~index) {
      const imageClone = imageList.slice();
      const base64Clone = imageBase64List.slice();
      const selectedImage = imageClone[index];
      if (selectedImage?.id) {
        setImageDeleteIds([...imageDeleteIds, selectedImage?.id]);
      }
      base64Clone.splice(index, 1);
      imageClone.splice(index, 1);
      setImageList(imageClone);
      setImageBase64List(base64Clone);
    }
  };

  const getStaticDropdownList = async () => {
    const res = await propertiesAPI?.getStaticProperties();
    if (res && res?.status === true) {
      let filterValue = {};
      Object.keys(res?.data)?.map((data) => {
        return (filterValue[data] = res?.data[data]?.map((list, index) => {
          return { id: index, label: list };
        }));
      });
      setDropdownValueList(filterValue || {});
      setToaster(false);
    } else {
      setToastError({
        title: 'API Fail',
        message: res?.data?.message || 'Something went to wrong!',
        type: 'error',
      });
      setToaster(true);
    }
  };

  const handleStepOneError = () => {
    const {
      address,
      monthlyRent,
      securityDeposit,
      totalBathroom,
      totalBedroom,
      dateAvailability,
    } = addPropertyForm;
    let error = {};
    if (!address) {
      error.address = 'Address is required';
    } else {
      delete error.address;
    }
    if (!monthlyRent) {
      error.monthlyRent = 'Monthly rent is required';
    } else {
      const value = number(monthlyRent);
      if (!value) {
        error.monthlyRent = 'Monthly rent must be in number value';
      } else {
        delete error.monthlyRent;
      }
    }
    if (!securityDeposit) {
      error.securityDeposit = 'Security deposit is required';
    } else {
      const value = number(securityDeposit);
      if (!value) {
        error.securityDeposit = 'Security deposit must be in number value';
      } else {
        delete error.securityDeposit;
      }
    }
    if (!totalBedroom) {
      error.totalBedroom = 'Total bedroom is required';
    } else {
      const value = number(totalBedroom);
      if (!value) {
        error.totalBedroom = 'total bedroom count must be in number value';
      } else {
        delete error.totalBedroom;
      }
    }
    if (!totalBathroom) {
      error.totalBathroom = 'Total bathroom is required';
    } else {
      const value = number(totalBathroom);
      if (!value) {
        error.totalBathroom = 'Total bathroom count must be in number value';
      } else {
        delete error.totalBathroom;
      }
    }
    if (!dateAvailability) {
      error.dateAvailability = 'Availability date is required';
    } else {
      const value = moment(dateAvailability)?._isValid;
      if (!value) {
        error.dateAvailability = 'Please enter valid date';
      } else {
        delete error.dateAvailability;
      }
    }
    return error;
  };

  const handleStepTwoError = () => {
    const { petPolicy, leaseDuration } = addPropertyForm;
    let error = {};
    if (!petPolicy) {
      error.petPolicy = 'Pet policy is required';
    } else {
      delete error.petPolicy;
    }
    if (!leaseDuration) {
      error.leaseDuration = 'Lease duration is required';
    } else {
      delete error.leaseDuration;
    }
    return error;
  };

  const handlePropertyUpdate = async () => {
    const error = { ...handleStepOneError(), ...handleStepTwoError() };
    setAddPropertyFormError(error);
    if (Object.keys(error).length) {
      return;
    }
    const fromData = new FormData();
    fromData.append('address', addPropertyForm?.address);
    fromData.append('bedrooms', addPropertyForm?.totalBedroom);
    fromData.append('bathrooms', addPropertyForm?.totalBathroom);
    fromData.append('monthly_rent', addPropertyForm?.monthlyRent);
    fromData.append('security_deposit', addPropertyForm?.securityDeposit);
    fromData.append('square_footage', addPropertyForm?.squareFootage);
    fromData.append('notes', addPropertyForm?.notes);
    fromData.append('status', addPropertyForm?.status || 'Review');
    fromData.append(
      'date_available',
      new Date(addPropertyForm?.dateAvailability)
    );
    fromData.append('lease_duration', addPropertyForm?.leaseDuration);
    fromData.append('pet_policy', addPropertyForm?.petPolicy);
    fromData.append(
      'amenities',
      (addPropertyForm?.amenities?.length &&
        addPropertyForm?.amenities?.map((list) => {
          return list?.label;
        })) ||
        []
    );
    fromData.append(
      'utilities',
      (addPropertyForm?.utilities?.length &&
        addPropertyForm?.utilities?.map((list) => {
          return list?.label;
        })) ||
        []
    );
    fromData.append('listings', addPropertyForm?.listings || []);
    fromData.append('description', addPropertyForm?.description);
    fromData.append('delete_images', imageDeleteIds || []);
    imageList &&
      imageList.length &&
      imageList?.map((image) => {
        if (!image?.id) {
          return fromData.append('images', image);
        }
      });
    setLoading(true);
    const res = await propertiesAPI?.updateProperty(details?._id, fromData, {
      contentType: 'multipart/form-data',
    });
    if (res && res?.status === true) {
      setLoading(false);
      setToaster(false);
      navigate('/properties');
    } else {
      setLoading(false);
      setToastError({
        title: 'API Fail',
        message: res?.data?.message || 'Something went to wrong!',
        type: 'error',
      });
      setToaster(true);
    }
  };

  const handleCheckboxControl = (e) => {
    const { name, value } = e?.target;
    const cloneAddForm = { ...addPropertyForm };
    const list = cloneAddForm?.listings;
    if (cloneAddForm?.listings?.length) {
      if (value && !list.some((data) => data === name)) {
        list.push(name);
      } else {
        const index = list.findIndex((key) => key === name);
        if (~index) {
          list.splice(index, 1);
        }
      }
    } else {
      if (value) {
        list.push(name);
      }
    }
    cloneAddForm.listings = list || [];
    setAddPropertyForm(cloneAddForm);
  };

  return (
    <div>
      {toaster && (
        <Toaster
          title={toastError?.title}
          type={toastError?.type}
          message={toastError?.message}
          handleCloseToaster={() => setToaster(false)}
        />
      )}
      {loading && <MainLoader />}
      <div className="edit-property-wrap">
        <div className="properties-step-box">
          <div className="step-inner">
            <div className="cds--row">
              <div className="cds--col-lg-16 mb-15">
                <TextFieldControl
                  labelText="Address*"
                  placeholder="Enter Address"
                  name="address"
                  id="address"
                  value={addPropertyForm?.address || ''}
                  onChange={handleChange}
                />
                {addPropertyFormError?.address && (
                  <label className="red-text">
                    {addPropertyFormError?.address}
                  </label>
                )}
              </div>
              <div className="cds--col-lg-8 mb-15">
                <TextFieldControl
                  labelText="Total Bedrooms*"
                  placeholder="bedroom"
                  name="totalBedroom"
                  id="bedroom"
                  value={addPropertyForm?.totalBedroom || ''}
                  onChange={handleChange}
                />
                {addPropertyFormError?.totalBedroom && (
                  <label className="red-text">
                    {addPropertyFormError?.totalBedroom}
                  </label>
                )}
              </div>
              <div className="cds--col-lg-8 mb-15">
                <TextFieldControl
                  labelText="Total Bathroom*"
                  placeholder="bathroom"
                  name="totalBathroom"
                  id="bathroom"
                  value={addPropertyForm?.totalBathroom || ''}
                  onChange={handleChange}
                />
                {addPropertyFormError?.totalBathroom && (
                  <label className="red-text">
                    {addPropertyFormError?.totalBathroom}
                  </label>
                )}
              </div>
              <div className="cds--col-lg-8 mb-15">
                <TextFieldControl
                  labelText="Monthly Rent*"
                  placeholder="Monthly Rent"
                  name="monthlyRent"
                  id="monthlyRent"
                  value={addPropertyForm?.monthlyRent || ''}
                  onChange={handleChange}
                />
                {addPropertyFormError?.monthlyRent && (
                  <label className="red-text">
                    {addPropertyFormError?.monthlyRent}
                  </label>
                )}
              </div>
              <div className="cds--col-lg-8 mb-15">
                <TextFieldControl
                  labelText="Security Deposit*"
                  placeholder="Security Deposit"
                  name="securityDeposit"
                  id="securityDeposit"
                  value={addPropertyForm?.securityDeposit || ''}
                  onChange={handleChange}
                />
                {addPropertyFormError?.securityDeposit && (
                  <label className="red-text">
                    {addPropertyFormError?.securityDeposit}
                  </label>
                )}
              </div>
              <div className="cds--col-lg-8 mb-15">
                <TextFieldControl
                  labelText="Square footage(optional)"
                  placeholder="Square footage"
                  name="squareFootage"
                  id="Square footage(optional)"
                  value={addPropertyForm?.squareFootage || ''}
                  onChange={handleChange}
                />
                {addPropertyFormError?.squareFootage && (
                  <label className="red-text">
                    {addPropertyFormError?.squareFootage}
                  </label>
                )}
              </div>
              <div className="cds--col-lg-8 mb-15">
                <DatePickerControl
                  labelText="Available date*"
                  name="dateAvailability"
                  onChange={(e) => {
                    let event = {
                      target: {
                        name: 'dateAvailability',
                        value: e && e?.length ? e?.[0] : '',
                      },
                    };
                    handleChange(event);
                  }}
                  value={
                    addPropertyForm?.dateAvailability
                      ? moment(addPropertyForm?.dateAvailability).format(
                          momentDateFormat
                        )
                      : ''
                  }
                />
                {addPropertyFormError?.dateAvailability && (
                  <label className="red-text">
                    {addPropertyFormError?.dateAvailability}
                  </label>
                )}
              </div>
            </div>
            <div className="cds--row">
              <div className="cds--col-lg-8 mb-15">
                <DropdownComboBoxControl
                  ariaLabel="ComboBox"
                  id="carbon-combobox-example"
                  // itemToString={(item) => (item ? item.text : '')}
                  label="Select"
                  name="leaseDuration"
                  titleText="Lease duration*"
                  value={addPropertyForm?.leaseDuration || ''}
                  items={dropdownValueList?.lease_duration || []}
                  onChange={(e) => {
                    const event = {
                      target: {
                        value: e?.selectedItem?.label,
                        name: 'leaseDuration',
                      },
                    };
                    handleChange(event);
                  }}
                />
                {addPropertyFormError?.leaseDuration && (
                  <label className="red-text">
                    {addPropertyFormError?.leaseDuration}
                  </label>
                )}
              </div>
              <div className="cds--col-lg-8 mb-15">
                <DropdownComboBoxControl
                  ariaLabel="ComboBox"
                  id="carbon-combobox-example"
                  // itemToString={(item) => (item ? item.text : '')}
                  label="Select"
                  titleText="Pet Policy*"
                  items={dropdownValueList?.pet_policy || []}
                  name="petPolicy"
                  value={addPropertyForm?.petPolicy || ''}
                  onChange={(e) => {
                    const event = {
                      target: {
                        value: e?.selectedItem?.label,
                        name: 'petPolicy',
                      },
                    };
                    handleChange(event);
                  }}
                />
                {addPropertyFormError?.petPolicy && (
                  <label className="red-text">
                    {addPropertyFormError?.petPolicy}
                  </label>
                )}
              </div>
              <div className="cds--col-lg-8 mb-15">
                <DropdownComboBoxControl
                  ariaLabel="ComboBox"
                  id={dynamicId()}
                  label="Status"
                  titleText="Status"
                  items={dropdownValueList?.property_status || []}
                  name="status"
                  value={addPropertyForm?.status || ''}
                  onChange={(e) => {
                    const event = {
                      target: {
                        value: e?.selectedItem?.label,
                        name: 'status',
                      },
                    };
                    handleChange(event);
                  }}
                />
              </div>
              {addPropertyForm?.amenities?.length > 0 && (
                <div className="cds--col-lg-16 mb-15">
                  <MultiSelectDropdownControl
                    ariaLabel="ComboBox"
                    id="carbon-combobox-example-test"
                    label="Select"
                    name="amenities"
                    titleText="Amenities(choose one or more options)"
                    initialSelectedItems={addPropertyForm?.amenities}
                    items={dropdownValueList?.amenities || []}
                    onChange={(e) => {
                      const event = {
                        target: {
                          value: e?.selectedItems || [],
                          name: 'amenities',
                        },
                      };
                      handleChange(event);
                    }}
                  />
                </div>
              )}
              {!addPropertyForm?.amenities?.length > 0 && (
                <div className="cds--col-lg-16 mb-15">
                  <MultiSelectDropdownControl
                    ariaLabel="ComboBox"
                    id="carbon-combobox-example-test"
                    label="Select"
                    name="amenities"
                    titleText="Amenities(choose one or more options)"
                    initialSelectedItems={addPropertyForm?.amenities}
                    items={dropdownValueList?.amenities || []}
                    onChange={(e) => {
                      const event = {
                        target: {
                          value: e?.selectedItems || [],
                          name: 'amenities',
                        },
                      };
                      handleChange(event);
                    }}
                  />
                </div>
              )}
              {addPropertyForm?.utilities?.length > 0 && (
                <div className="cds--col-lg-16 mb-15">
                  <MultiSelectDropdownControl
                    initialSelectedItems={addPropertyForm?.utilities || []}
                    ariaLabel="ComboBox"
                    id="carbon-combobox-example"
                    label="Select"
                    titleText="Utilities included in rent(choose one or more options)"
                    items={dropdownValueList?.utilities || []}
                    name="utilities"
                    onChange={(e) => {
                      const event = {
                        target: {
                          value: e?.selectedItems || [],
                          name: 'utilities',
                        },
                      };
                      handleChange(event);
                    }}
                  />
                </div>
              )}
              {!addPropertyForm?.utilities?.length > 0 && (
                <div className="cds--col-lg-16 mb-15">
                  <MultiSelectDropdownControl
                    initialSelectedItems={addPropertyForm?.utilities || []}
                    ariaLabel="ComboBox"
                    id="carbon-combobox-example"
                    label="Select"
                    titleText="Utilities included in rent(choose one or more options)"
                    items={dropdownValueList?.utilities || []}
                    name="utilities"
                    onChange={(e) => {
                      const event = {
                        target: {
                          value: e?.selectedItems || [],
                          name: 'utilities',
                        },
                      };
                      handleChange(event);
                    }}
                  />
                </div>
              )}
              <div className="cds--col-lg-16 mb-15">
                <TextAreaControl
                  id="Description"
                  labelText="Description"
                  name="description"
                  value={addPropertyForm?.description || ''}
                  placeholder="Enter property description"
                  rows={4}
                  onChange={handleChange}
                />
              </div>
              <div className="cds--col-lg-16 mb-15">
                <TextAreaControl
                  id="Notes"
                  labelText="Notes"
                  name="notes"
                  value={addPropertyForm?.notes || ''}
                  placeholder="Enter property Notes"
                  rows={4}
                  onChange={handleChange}
                />
              </div>
              <div className="cds--col-lg-16 mb-15">
                <fieldset className="cds--fieldset">
                  <legend className="cds--label">Listings</legend>
                  <CheckboxControl
                    name="Zumper"
                    labelText="Zumper"
                    id="1"
                    checked={addPropertyForm?.listings?.includes('Zumper')}
                    onChange={handleCheckboxControl}
                  />
                  <CheckboxControl
                    name="Zillow"
                    labelText="Zillow"
                    id="2"
                    checked={addPropertyForm?.listings?.includes('Zillow')}
                    onChange={handleCheckboxControl}
                  />
                  <CheckboxControl
                    name="Apartments"
                    labelText="Apartments"
                    id="3"
                    checked={addPropertyForm?.listings?.includes('Apartments')}
                    onChange={handleCheckboxControl}
                  />
                  {/* <CheckboxControl
                    name="Rentpath"
                    checked={addPropertyForm?.listings?.includes('Rentpath')}
                    labelText="Rentpath"
                    id="4"
                    onChange={handleCheckboxControl}
                  />
                  <CheckboxControl
                    checked={addPropertyForm?.listings?.includes('Lorem')}
                    name="Lorem"
                    labelText="Lorem"
                    id="5"
                    onChange={handleCheckboxControl}
                  /> */}
                </fieldset>
              </div>
            </div>
            <div className="cds--row">
              <div className="cds--col-lg-16 mb-15">
                <span>Property images</span>
                <div className="upload_text">
                  {imageBase64List &&
                    imageBase64List?.length > 0 &&
                    imageBase64List?.map((image, index) => {
                      return (
                        <div key={index} className="upload-image">
                          <span onClick={() => handleRemoveImage(index)}>
                            <img src={allImage?.closeIcon} alt="delete" />
                          </span>
                          <img key={index} src={image || ''} alt={'property'} />
                        </div>
                      );
                    })}
                  {refresh !== 'image' && (
                    <div className="file-upload">
                      <TextFieldControl
                        multiple
                        name="image"
                        labelText=""
                        style={{ display: 'none' }}
                        type="file"
                        id="upload-profile-img"
                        onChange={handleChangeImage}
                      />
                      <label htmlFor="upload-profile-img">
                        <img src={allImage?.uploadIcon} alt="upload" />
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="step-btn mb-15">
            <ButtonControl kind="primary" onClick={handlePropertyUpdate}>
              Save
            </ButtonControl>
          </div>
        </div>
      </div>
    </div>
  );
}
