import { get } from '../base';
const endPoint = 'admin/lead';

const getLeads = async (params) => {
  const response = await get(`${endPoint}`, params);
  return response;
};

const getLeadsCount = async () => {
  const response = await get(`${endPoint}/count`);
  return response;
};

export const leadsAPI = {
  getLeads,
  getLeadsCount,
};
