import axios from 'axios';
import qs from 'qs';
import { getToken, loggedOutUser } from '../Utils/common';
import { SERVER_URL } from '../Utils/config';
const baseURL = SERVER_URL;

const handleErrors = (error) => {
  //TODO implement logic to handle/record errors
  console.log('error', error?.response);
  if (
    error?.response?.status === 401 ||
    error?.response?.data === 'Unauthorized'
  ) {
    loggedOutUser();
    window.location.href = `${window?.location?.origin}/login`;
  }
  return { status: false, data: error?.response?.data || error };
};

//apply paramsSerializer to all requests
axios.interceptors.request.use((config) => {
  return {
    ...config,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  };
});

const headerAuthorization = () => {
  const token = getToken();
  if (token) {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    return header;
  } else {
    return false;
  }
};

export const get = async (url, params = {}, headers = {}) => {
  const authorization = headerAuthorization();
  if (authorization) {
    headers = { ...headers, ...authorization };
  }
  try {
    const result = await axios.get(baseURL + url, {
      params: params,
      headers: {
        ...headers,
      },
    });
    return { status: true, data: result?.data };
  } catch (e) {
    return handleErrors(e);
  }
};

export const post = async (url, body = {}, headers = {}) => {
  const authorization = headerAuthorization();
  if (authorization) {
    headers = { ...headers, ...authorization };
  }
  try {
    const result = await axios.post(baseURL + url, body, { headers: headers });
    return { status: true, data: result?.data };
  } catch (e) {
    return handleErrors(e);
  }
};

export const put = async (url, body = {}, headers = {}) => {
  const authorization = headerAuthorization();
  if (authorization) {
    headers = { ...headers, ...authorization };
  }
  try {
    const result = await axios.put(baseURL + url, body, { headers: headers });
    return { status: true, data: result?.data };
  } catch (e) {
    return handleErrors(e);
  }
};

export const patch = async (url, body = {}, headers = {}) => {
  const authorization = headerAuthorization();
  if (authorization) {
    headers = { ...headers, ...authorization };
  }
  try {
    const result = await axios.patch(baseURL + url, body, { headers: headers });
    return { status: true, data: result?.data };
  } catch (e) {
    return handleErrors(e);
  }
};

export const deletes = async (url, params = {}, body = {}, headers = {}) => {
  const authorization = headerAuthorization();
  if (authorization) {
    headers = { ...headers, ...authorization };
  }
  try {
    const result = await axios.delete(baseURL + url, {
      params: params,
      body: body,
      headers: {
        ...headers,
      },
    });
    return { status: true, data: result?.data };
  } catch (e) {
    return handleErrors(e);
  }
};
