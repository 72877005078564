import React, { useState } from 'react';
import { Search } from '@carbon/icons-react';
import TextFieldControl from '../TextField';
import ButtonControl from '../Button';

export default function SearchFilter({ handleChange, handleSearch, value }) {
  const [searchValue, setSearchValue] = useState(value || '');

  const onChangeSearch = (e) => {
    setSearchValue(e);
    handleChange && handleChange(e);
  };

  return (
    <div className="table-search-box">
      <Search />
      <TextFieldControl
        id="search"
        placeholder="Search"
        type="text"
        labelText=""
        value={searchValue || ''}
        onChange={(e) => onChangeSearch(e?.target?.value)}
      />
      <ButtonControl onClick={handleSearch}>
        Search
      </ButtonControl>
    </div>
  );
}
