import React, { useEffect, useState } from 'react';
import SiteHeading from '../../Common/SiteHeading';
import ApplicantsDetailsComponent from '../../Applicants/applicantsDetails';
import { breadcrumb } from '../../../Utils/breadcrumbMenus';
import { useLocation } from 'react-router-dom';
import Toaster from '../../Common/Toaster';
import MainLoader from '../../Common/Loader/MainLoader';
import { applicantAPI } from '../../../API/Applicant';
import { emailValidation, PhoneNumber } from '../../../Utils/Regex';

let customMenu = [];
const ApplicantsDetails = () => {
  const location = useLocation();
  const { state, pathname } = location;
  const [details, setDetails] = useState({});
  const [refresh, setRefresh] = useState(true);
  const [applicantDetails, setApplicantDetails] = useState({});
  const [updatedDetails, setUpdatedDetails] = useState({});
  const [toastError, setToastError] = useState({});
  const [disableBtn, setDisableBtn] = useState(true);
  const [toaster, setToaster] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (state?.details?.address) {
      if (!customMenu.some(({ title }) => title === details?.address)) {
        customMenu = [];
        customMenu = breadcrumb?.propertiesDetailsPage.slice();
        customMenu.push({
          title: state?.details?.address,
          path: `/properties/property-detail/${state?.details?._id}`,
          redirect: true,
        });
        customMenu.push({
          title: 'Applicants',
          path: `/properties/property-detail/applicant/${state?.details?._id}`,
          redirect: true,
        });
        customMenu.push({
          title: 'Applicant Details',
          path: `/properties/property-detail/${state?.details?.address}`,
          redirect: false,
        });
        setRefresh(!refresh);
      }
    }
  }, [state?.details]);

  useEffect(() => {
    if (state?.value) {
      handleGetApplicantDetails(state?.value);
    }
  }, [state?.value]);

  const handleGetApplicantDetails = async (id) => {
    setLoading(true);
    const res = await applicantAPI?.getApplicant(id);
    if (res && res?.status === true) {
      setApplicantDetails(res?.data || {});
      setLoading(false);
    } else {
      setToastError({
        title: 'API Fail',
        message: res?.data?.message || 'Something went to wrong!',
        type: 'error',
      });
      setLoading(false);
      setToaster(true);
    }
  };

  const handleUpdateApplicantDetails = async () => {
    if (updatedDetails && Object.keys(updatedDetails)?.length) {
      if (updatedDetails?.email) {
        const value = emailValidation(updatedDetails?.email);
        if (!value) {
          setToaster(true);
          setToastError({
            title: 'Validation Fail',
            message: 'Please enter valid email',
            type: 'error',
          });
          return;
        }
      }

      if (updatedDetails?.payment_email) {
        const value = emailValidation(updatedDetails?.payment_email);
        if (!value) {
          setToaster(true);
          setToastError({
            title: 'Validation Fail',
            message: 'Please enter valid payment email',
            type: 'error',
          });
          return;
        }
      }

      if (updatedDetails?.phone) {
        const value = PhoneNumber(updatedDetails?.phone);
        if (!value) {
          setToaster(true);
          setToastError({
            title: 'Validation Fail',
            message: 'Please enter valid phone',
            type: 'error',
          });
          return;
        }
      }

      if (updatedDetails?.phone_number) {
        const value = PhoneNumber(updatedDetails?.phone_number);
        if (!value) {
          setToaster(true);
          setToastError({
            title: 'Validation Fail',
            message: 'Please enter valid phone number',
            type: 'error',
          });
          return;
        }
      }

      if (updatedDetails?.phone_supervisor_or_hr) {
        const value = PhoneNumber(updatedDetails?.phone_supervisor_or_hr);
        if (!value) {
          setToaster(true);
          setToastError({
            title: 'Validation Fail',
            message: 'Please enter valid phone number of supervisor / HR',
            type: 'error',
          });
          return;
        }
      }

      setLoading(true);
      const res = await applicantAPI?.updateApplicant(
        state?.value || pathname,
        updatedDetails
      );
      if (res && res?.status === true) {
        setDisableBtn(true);
        setUpdatedDetails({});
        setLoading(false);
        setToaster(true);
        setToastError({
          title: 'API Success',
          message: res?.data?.message || 'Applicants update successfully',
          type: 'error',
        });
      } else {
        setToastError({
          title: 'API Fail',
          message: res?.data?.message || 'Something went to wrong!',
          type: 'error',
        });
        setLoading(false);
        setToaster(true);
      }
    }
  };

  const handleChange = (event) => {
    let { name, value } = event?.target;
    setDisableBtn(false);
    if (name === 'fullName') {
      let splitData = value && value.split(' ');
      setUpdatedDetails({
        ...updatedDetails,
        ['first_name']: splitData?.[0] || '',
        ['last_name']: splitData?.[1] || '',
      });
      setApplicantDetails({
        ...applicantDetails,
        ['first_name']: splitData?.[0] || '',
        ['last_name']: splitData?.[1] || '',
      });
    } else {
      if(name === 'quantity'
      || name === 'current_monthly_rent'
      || name === 'minor_occupants'
      || name === 'adult_occupants'
      || name === 'monthly_household_income'
      || name === 'unit_price'
      || name === 'sub_total'
      || name === 'order'
      || name === 'household_income_people'
      ){ 
        value = Number(value);
      }
      setUpdatedDetails({
        ...updatedDetails,
        [name]: value,
      });
      setApplicantDetails({
        ...applicantDetails,
        [name]: value,
      });
    }
  };

  return (
    <>
      {toaster && (
        <Toaster
          title={toastError?.title}
          type={toastError?.type}
          message={toastError?.message}
          handleCloseToaster={() => setToaster(false)}
        />
      )}
      {loading && <MainLoader />}
      <SiteHeading
        Heading="Applicants Details"
        menu={customMenu}
      />
      <ApplicantsDetailsComponent
        {...{ applicantDetails, handleChange, handleUpdateApplicantDetails, disableBtn }}
      />
    </>
  );
};

export default ApplicantsDetails;
