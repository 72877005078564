import { allImage } from '../../Utils/allImageImport';
export const LandLordMenuList = [
  {
    title: 'Home',
    path: '/home',
    icon: allImage?.homeMenu,
    subMenu: false,
    menu: [],
  },
  {
    title: 'Managers',
    path: '/managers',
    icon: allImage?.managerIcon,
    subMenu: false,
    menu: [],
  },
  {
    title: 'Landlords',
    path: '/landlords',
    icon: allImage?.landLords,
    subMenu: false,
    menu: [],
  },
  {
    title: 'Properties',
    path: '/properties',
    icon: allImage?.propertiesMenu,
    subMenu: false,
    menu: [],
  },
  {
    title: 'Property Review',
    path: '/property-review',
    icon: allImage?.propertiesReview,
    subMenu: false,
    menu: [],
  },
  {
    title: 'Unlist property',
    path: '/unlist-property',
    icon: allImage?.unlistedProperty,
    subMenu: false,
    menu: [],
  },
  {
    title: 'Subscriptions',
    path: '/subscriptions',
    icon: allImage?.subscription,
    subMenu: false,
    menu: [],
  },
];
