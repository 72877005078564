import { get, patch } from '../base';
const endPoint = 'admin/applicants';

const getPropertyWiseApplicant = async (params) => {
  const response = await get(`${endPoint}`, params);
  return response;
};

const getApplicant = async (id, params) => {
  const response = await get(`${endPoint}/${id}`, params);
  return response;
};

const getApplicantCount = async () => {
  const response = await get(`${endPoint}/count`);
  return response;
};

const updateApplicant = async (id, data) => {
  const response = await patch(`${endPoint}/${id}`, data);
  return response;
};

export const applicantAPI = {
  getApplicant,
  getApplicantCount,
  getPropertyWiseApplicant,
  updateApplicant,
};
