import { Content } from '@carbon/react';
import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.scss';
import ForgotPassword from './Components/Pages/Auth/forgotPassword';
import Login from './Components/Pages/Auth/login';
import Signup from './Components/Pages/Auth/signup';
import Home from './Components/Pages/Home';
import LandLord from './Components/Pages/Landlord';
import LandlordDetail from './Components/Pages/Landlord/LandlordDetail';
import Properties from './Components/Pages/Properties';
import EditProperty from './Components/Pages/Properties/editProperty';
import PropertyDetail from './Components/Pages/Properties/propertyDetail';
import Applicants from './Components/Pages/Applicants';
import ApplicantsDetails from './Components/Pages/Applicants/applicantsDetails';
import InvitePage from './Components/Pages/Invite';
import Lead from './Components/Pages/Lead';
import { Layout } from './Layout';
import PrivateRoute from './Routes/private';
import { userAuth } from './Utils/common';
import { publicRoutes, removeLayoutStyleRoutes } from './Utils/constants';
import UnListProperty from './Components/Pages/Properties/unListProperty';
import ReviewProperty from './Components/Pages/Properties/reviewProperty';

function App() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!userAuth() && !publicRoutes?.includes(pathname)) {
      navigate('/login');
    }
  }, [pathname, navigate]);

  return (
    <div className="App">
      {removeLayoutStyleRoutes?.includes(pathname) ? null : <Layout />}
      <Content
        className={
          removeLayoutStyleRoutes?.includes(pathname)
            ? 'cds--padding-0'
            : 'main-content'
        }
      >
        <Routes>
          <Route
            exact
            path="/home"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/properties/property-detail/applicant/:id"
            element={
              <PrivateRoute>
                <Applicants />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/properties/property-detail/applicant/edit/:id"
            element={
              <PrivateRoute>
                <ApplicantsDetails />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/properties"
            element={
              <PrivateRoute>
                <Properties />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/properties/property-detail/:id"
            element={
              <PrivateRoute>
                <PropertyDetail />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/properties/edit-property/:id"
            element={
              <PrivateRoute>
                <EditProperty />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/managers"
            element={
              <PrivateRoute>
                <InvitePage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/landlords"
            element={
              <PrivateRoute>
                <LandLord />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/landlords/landlords-detail/:id"
            element={
              <PrivateRoute>
                <LandlordDetail />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/property-review"
            element={
              <PrivateRoute>
                <ReviewProperty />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/unlist-property"
            element={
              <PrivateRoute>
                <UnListProperty />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/subscriptions"
            element={
              <PrivateRoute>
                <InvitePage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/properties/property-detail/lead/:id"
            element={
              <PrivateRoute>
                <Lead />
              </PrivateRoute>
            }
          />

          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/*" element={<Login />} />
        </Routes>
      </Content>
    </div>
  );
}

export default App;
