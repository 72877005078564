import React, { useEffect, useState } from 'react';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { propertiesAPI } from '../../API/properties';
import { allImage } from '../../Utils/allImageImport';
import ButtonControl from '../Common/Button';
import MainLoader from '../Common/Loader/MainLoader';
import Modal from '../Common/Modal';
import Toaster from '../Common/Toaster';
import { dynamicId } from '../../Utils/common';
import DropdownComboBoxControl from '../Common/Dropdown/comboBox';
import Tooltip from '../Common/Tooltip';
import { useNavigate } from 'react-router-dom';

const PropertyDetailComponents = ({ details, handleRefresh }) => {
  const navigate = useNavigate();
  const [requestModel, setRequestModal] = useState(false);
  const [toastError, setToastError] = useState({});
  const [toaster, setToaster] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [leadAndApplicantCount, setLeadAndApplicantCount] = useState({});

  useEffect(() => {
    if (details?._id) {
      handleGetLeadAndApplicantCount(details?._id);
    }
  }, [details?._id]);

  const handleRequestModal = () => {
    setRequestModal(true);
  };

  const requestModalClose = () => {
    setRequestModal(false);
  };

  const handleGetLeadAndApplicantCount = async (id) => {
    const res = await propertiesAPI?.getCountOfApplicantAndLead(id);
    if (res && res?.status === true) {
      setLeadAndApplicantCount(res?.data || {});
      setLoading(false);
    } else {
      setToastError({
        title: 'API Fail',
        message: res?.data?.message || 'Something went to wrong!',
        type: 'error',
      });
      setToaster(true);
    }
  };

  const handleUpdatePropertyStatus = async () => {
    if (details?._id) {
      const payload = {
        status: currentStatus,
      };
      setLoading(true);
      const res = await propertiesAPI?.updatePropertyStatus(
        details?._id,
        payload
      );
      if (res && res?.status === true) {
        handleRefresh();
        setCurrentStatus(null);
        setLoading(false);
        setToaster(true);
        setToastError({
          title: 'Success',
          message: res?.data?.message || 'Something went to wrong!',
          type: 'success',
        });
      } else {
        setLoading(false);
        setToastError({
          title: 'API Fail',
          message: res?.data?.message || 'Something went to wrong!',
          type: 'error',
        });
        setToaster(true);
      }
    }
  };

  const handleUnListProperty = async () => {
    requestModalClose();
    if (details?._id) {
      setLoading(true);
      const payload = {
        status: 'Un list Requested',
      };
      const res = await propertiesAPI?.updatePropertyStatus(
        details?._id,
        payload
      );
      if (res && res?.status === true) {
        handleRefresh();
        setLoading(false);
        setToaster(true);
        setToastError({
          title: 'Success',
          message: res?.data?.message || 'Something went to wrong!',
          type: 'success',
        });
      } else {
        setLoading(false);
        setToastError({
          title: 'API Fail',
          message: res?.data?.message || 'Something went to wrong!',
          type: 'error',
        });
        setToaster(true);
      }
    }
  };

  const overview = [
    {
      image: allImage.badRoom,
      title: `${details?.bedrooms || 0} Bedrooms`,
    },
    {
      image: allImage.bathRoom,
      title: `${details?.bathrooms || 0} Bathrooms`,
    },
    {
      image: allImage.sqftIcon,
      title: `${(details?.square_footage &&
        details?.square_footage?.toString()?.replace('sqft', '')) ||
        'N/A'
        }  sqft`,
    },
    {
      image: allImage.dateIcon,
      title: new Date(details?.createdAt).toLocaleDateString(),
    },
  ];

  const detail = [
    {
      title: 'Monthly Rent',
      value: `$${details?.monthly_rent}`,
    },
    {
      title: 'Lease duration',
      value: details?.lease_duration,
    },
    {
      title: 'Pet policy',
      value: details?.pet_policy,
    },
    {
      title: 'Security deposit',
      value: `$${details?.security_deposit}`,
    },
  ];

  const ReviewMenu = [
    { id: 1, label: 'Submitted for Listing' },
    { id: 2, label: 'Listed' },
  ];

  const unListedMenu = [
    { id: 1, label: 'Un list Requested' },
    { id: 2, label: 'Un listed' },
  ];

  const handleChangeStatus = (e) => {
    setCurrentStatus(e?.selectedItem?.label);
  };

  const utilitiesIcon = (key) => {
    switch (key) {
      case 'Cable':
        return allImage?.cableIcon;
      case 'Electric':
        return allImage?.electricIcon;
      case 'Gas':
        return allImage?.gasIcon;
      case 'Air Conditioning':
        return allImage?.acIcon;
      case 'Water':
        return allImage?.waterIcon;
      case 'Trash':
        return allImage?.trashIcon;
      case 'Telephone':
        return allImage?.telephoneIcon;
      case 'Sewer':
        return allImage?.sewerIcon;
      case 'Satellite':
        return allImage?.satelliteIcon;
      case 'Hot Water':
        return allImage?.hotWaterIcon;
      case 'High Speed Internet':
        return allImage?.wifiIcon;
      case 'Heat':
        return allImage?.heatIcon;
      default:
        return allImage?.electricIcon;
    }
  };

  const amenitiesIcon = (key) => {
    switch (key) {
      case 'Balcony or deck':
        return allImage?.balconyAndDeckIcon;
      case 'Disabled access':
        return allImage?.disabledAccessIcon;
      case 'Dishwasher':
        return allImage?.dishwasherIcon;
      case 'Air conditioning':
        return allImage?.acIcon;
      case 'Dryer':
        return allImage?.dryerIcon;
      case 'Furnished':
        return allImage?.furnishedIcon;
      case 'Garage parking':
        return allImage?.garageParkingIcon;
      case 'Hardwood floors':
        return allImage?.hardwoodFloorsIcon;
      case 'Off-street parking':
        return allImage?.offStreetParkingIcon;
      case 'Pool Bicycle storage':
        return allImage?.poolBicycleStorageIcon;
      case 'Security alarm':
        return allImage?.securityAlarmIcon;
      case 'Washer':
        return allImage?.washerIcon;
      default:
        return allImage?.electricIcon;
    }
  };

  const handleRedirectLead = () => {
    navigate(`/properties/property-detail/lead/${details?._id}`, {
      state: { id: details?._id, details: details },
    });
  };

  const handleRedirectApplicant = () => {
    navigate(`/properties/property-detail/applicant/${details?._id}`, {
      state: { id: details?._id, details: details },
    });
  };

  const listValue = currentStatus ? currentStatus : details?.status || '';
  const statusMenuList =
    details?.status === 'Submitted for Listing'
      ? ReviewMenu
      : details?.status === 'Un list Requested'
        ? unListedMenu
        : [{ id: 1, label: details?.status }];

  return (
    <>
      <div className="property-detail-wrap">
        {toaster && (
          <Toaster
            title={toastError?.title}
            type={toastError?.type}
            message={toastError?.message}
            handleCloseToaster={() => setToaster(false)}
          />
        )}
        {loading && <MainLoader />}
        {/* <p>{details?.address}</p> */}
        <div className="lead-appli-info">
          <div className="property-lead-applicant">
            <Tooltip
              label="The number of people who have inquired about your property but haven’t submitted an application yet"
              className="tooltip"
              align="bottom-right"
              defaultOpen={false}
            >
              <span>
                <b>{leadAndApplicantCount?.leadCount || 0}</b> Inquiries
              </span>
            </Tooltip>
            <Tooltip
              label="The number of people who have submitted an application to us"
              className="tooltip"
              align="top-right"
              defaultOpen={false}
            >
              <span>
                <b>{leadAndApplicantCount?.applicantCount || 0}</b> Applicants
              </span>
            </Tooltip>
          </div>
        </div>
        <div className="cds--row">
          <div className="cds--col-lg-10">
            <div className="property-images">
              {details && details?.images?.length > 0 ? (
                <Swiper
                  pagination={{
                    type: 'progressbar',
                  }}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                  className="mySwiper"
                >
                  {details?.images.map((list, index) => {
                    return (
                      <React.Fragment key={index}>
                        <SwiperSlide key={index}>
                          <div
                            className={`category ${details?.status === 'Draft' ? 'draft' : (details?.status === 'Submitted for Listing' ? 'review' : 'listed')
                              }`}
                            key={index}
                          >
                            {details?.status}
                          </div>
                          <img
                            src={list?.url || allImage?.noImageAvailableIcon}
                            alt="detail"
                          />
                        </SwiperSlide>
                      </React.Fragment>
                    );
                  })}
                </Swiper>
              ) : (
                <img
                  src={allImage?.noImageAvailableIcon}
                  alt="detail"
                  className="remove-cover-fit"
                />
              )}
            </div>
          </div>
          <div className="cds--col-lg-6">
            <div className="property-applicant">
              <DropdownComboBoxControl
                id={dynamicId()}
                label=""
                titleText="Status"
                value={listValue}
                onChange={handleChangeStatus}
                items={statusMenuList}
              />
              {currentStatus && (
                <ButtonControl
                  kind="secondary"
                  disabled={currentStatus ? false : true}
                  onClick={handleUpdatePropertyStatus}
                >
                  Save
                </ButtonControl>
              )}
              <div className="property-action-btn">
                <ButtonControl kind="secondary" onClick={handleRedirectLead}>
                  <span>Inquiries</span>
                  <img src={allImage?.rightIcon} alt="right-icon" />
                </ButtonControl>
                <ButtonControl onClick={handleRedirectApplicant}>
                  <span>Applicants</span>
                  <img src={allImage?.rightIcon} alt="right-icon" />
                </ButtonControl>
              </div>
            </div>
          </div>
        </div>
        <div className="property-details">
          <div className="listing-row">
            <h5>Overview</h5>
            <ul className="overview-list">
              {overview.map((data, i) => {
                return (
                  <li key={i}>
                    <img src={data.image} alt="icon" />{' '}
                    <span>{data.title}</span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="listing-row">
            <h5>Description</h5>
            <p>
              {details?.description === 'undefined' || !details?.description
                ? 'Description not available yet.'
                : details?.description}
            </p>
          </div>
          <div className="listing-row">
            <h5>Listings</h5>
            <ul className="listings-list">
              {details?.listings && details?.listings?.length > 0
                ? details?.listings?.map((list, i) => {
                  return (
                    <li key={i}>
                      <span>{list?.name || ''}</span>
                    </li>
                  );
                })
                : 'Currently property Listing details are not available yet.'}
            </ul>
          </div>
          <div className="listing-row">
            <h5>Details</h5>
            <ul className="details-list">
              {detail.map((data, i) => {
                return (
                  <li key={i}>
                    <span>{data.title}</span>
                    <h6>{data.value}</h6>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="listing-row">
            <h5>Amenities</h5>
            <ul className="amenites-utilities-list">
              {details?.amenities?.map((data, i) => {
                return (
                  <li key={i}>
                    <img src={amenitiesIcon(data)} alt="icon" />
                    <span>{data}</span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="listing-row">
            <h5>Utilities included in rent</h5>
            <ul className="amenites-utilities-list">
              {details?.utilities?.map((data, i) => {
                return (
                  <li key={i}>
                    <img src={utilitiesIcon(data)} alt="icon" />
                    <span>{data}</span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="listing-row mb-15">
            {(details?.listings && details?.listings?.length > 0) ||
              (details?.status === 'Listed' && (
                <ButtonControl kind="danger" onClick={handleRequestModal}>
                  Request to unlist property
                </ButtonControl>
              ))}
            <Modal
              className="unlist-modal"
              open={requestModel}
              onClose={() => requestModalClose()}
              title="Are you sure"
            >
              <p>your property will be unlisted within 24 hours</p>
              <div className="unlisted-btn">
                <ButtonControl
                  kind="secondary"
                  onClick={() => requestModalClose()}
                >
                  Cancel
                </ButtonControl>
                <ButtonControl kind="danger" onClick={handleUnListProperty}>
                  Unlist
                </ButtonControl>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyDetailComponents;
