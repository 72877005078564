import { get, post, deletes, patch } from '../base';
const endPoint = 'admin/invites';

const allInvitedManagersAndAdmins = async (params) => {
  const response = await get(`${endPoint}`, params);
  return response;
};

const sendInvite = async (data) => {
  const response = await post(`${endPoint}`, data);
  return response;
};

const deactivateUser = async (id) => {
  const response = await patch(`${endPoint}`, id);
  return response;
};

export const invitesAPI = {
  allInvitedManagersAndAdmins,
  sendInvite,
  deactivateUser,
};
