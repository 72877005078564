//date show like 15-05-2022
export const dateFormat = 'd-m-yy';

//date show like 15-05-2022
export const dateFormatTable = 'DD-MM-YYYY';

//date show like 15-05-2022
export const momentDateFormat = 'DD-MM-YYYY';

export const publicRoutes = ['/login', '/signup', '/forgot-password'];
export const removeLayoutStyleRoutes = [
  '/login',
  '/signup',
  '/forgot-password',
  '/email-verify',
];
