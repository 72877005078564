import React, { useEffect, useState } from 'react';
import DropdownComboBoxControl from '../Common/Dropdown/comboBox';
import TextFieldControl from '../Common/TextField';
import ButtonControl from '../Common/Button';
import DataTableControl from '../Common/DataTable';
import { dateFormatTable } from '../../Utils/constants';
import moment from 'moment';
import { dynamicId } from '../../Utils/common';
import { invitesAPI } from '../../API/Invites';
import ToggleControl from '../Common/Toggle';
import Toaster from '../Common/Toaster';
import Swal from 'sweetalert2';

export default function InvitesComponent() {
    const [loading, setLoading] = useState(false);
    const [toaster, setToaster] = useState(false);
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const [toastError, setToastError] = useState({});
    const [userDetails, setUserDetails] = useState({
        email: '',
        first_name: '',
        last_name: '',
        role: '',
    });
    const [addUserDetailFormError, setAddUserDetailFormError] = useState({});
    const [error, setError] = useState({
        title: '',
        message: '',
        timeOut: '',
        type: '',
        subTitle: '',
    });
    const roleMenuList = [
        {
            id: 1,
            label: "Admin",
        },
        {
            id: 2,
            label: "Manager",
        },
    ];

    const getTableData = async (filter) => {
        setLoading(true);
        filter.$page = filter.$page || 1;
        filter.$limit = filter.$limit || 10;
        filter.filter = filter.filter || {};

        const res = await invitesAPI?.allInvitedManagersAndAdmins(filter);
        if (res || !res) setLoading(false);
        if (res && res?.status === true) {
            toaster && setToaster(false);
            localStorage.setItem('tableData', JSON.stringify(res?.data?.docs));
            return res?.data;
        } else {
            setToaster(true);
            setError({
                ...error,
                title: 'API Fail',
                message: res?.data?.message || 'Something went to wrong!',
                type: 'error',
            });
            return [];
        }
    };

    const deactiveUser = async (id, value) => {
        setLoading(true);
        id = id.split(':')[0];
        const res = await invitesAPI?.deactivateUser({ id, active: value });
        if (res && res?.status === true) {
            setTriggerUpdate(prev => !prev);
            setToastError({
                title: 'API Success',
                message: value ? 'User activated successfully!' : 'User deactivated successfully!',
                type: 'success',
            });
        } else {
            setToastError({
                title: 'API Fail',
                message: res?.data?.message || 'Something went to wrong!',
                type: 'error',
            });
        }
        setLoading(false);
        setToaster(true);
    };

    const headingData = [
        {
            key: 'first_name',
            sortable: true,
            header: 'First Name',
        },
        {
            key: 'last_name',
            sortable: false,
            header: 'Last Name',
        },
        {
            key: 'email',
            sortable: true,
            header: 'Email',
        },
        {
            key: 'role',
            sortable: false,
            header: 'Role',
            render: (value) => {
                return (
                    <span className={`status ${value === "admin" ? "info" : "sky"}`}>{value}</span>
                );
            },
        },
        {
            key: 'active',
            sortable: false,
            header: 'Active',
            render: (value, id) => {
                const { role } = JSON.parse(localStorage.getItem('userData'));
                const filterById = JSON.parse(localStorage.getItem('tableData')).filter((data) => data._id === (id.split(':')[0]));
                if (filterById[0]?.role === "admin" && role === "manager") {
                    return (
                        <ToggleControl id={id} toggled={value} onToggle={() => deactiveUser(id, !value)} disabled={true} />
                    );
                }
                return (
                    <ToggleControl id={id} toggled={value} onToggle={() => deactiveUser(id, !value)} />
                );
            },
        },
        {
            key: 'createdAt',
            sortable: false,
            header: 'Signup Date',
            render: (value) => {
                return (
                    <span> {(value && moment(value).format(dateFormatTable)) || ''}</span>
                );
            },
        },
    ];


    const fillUserDetails = (e) => {
        const { name, value } = e.target;
        setUserDetails((prev) => {
            return {
                ...prev,
                [name]: value.toLowerCase(),
            }
        });
    };

    const handleError = () => {
        const { email, first_name, last_name, role } = userDetails;
        let error = {};
        if (!email || email === '') {
            error.email = 'Email is required';
        } else {
            delete error.email;
        }
        if (!first_name || first_name === '') {
            error.first_name = 'First Name is required';
        } else {
            delete error.first_name;
        }
        if (!last_name || last_name === '') {
            error.last_name = 'Last Name is required';
        } else {
            delete error.last_name;
        }
        if (!role || role === '') {
            error.role = 'Role is required';
        } else {
            delete error.role;
        }
        return error;
    };

    const clearForm = () => {
        setUserDetails({
            email: '',
            first_name: '',
            last_name: '',
            role: '',
        });
    };

    const addUserInvite = async () => {
        const error = { ...handleError() };
        setAddUserDetailFormError(error);
        if (Object.keys(error).length) {
            return;
        }
        setLoading(true);
        const res = await invitesAPI?.sendInvite(userDetails);
        if (res && res?.status === true) {
            console.log(res);
            clearForm();
            setTriggerUpdate(prev => !prev);
            Swal.fire({
                icon: "success",
                title: "User invited successfully!",
                html: `<h4><span style="font-weight:bolder; padding-bottom:5px;">Email:</span>  ${res?.data?.email}</h4>
                       <h4><span style="font-weight:bolder;">Password:</span>  ${res?.data?.password}<h4>`,
                padding: '1rem',
            });
        } else {
            setToastError({
                title: 'API Fail',
                message: res?.data?.message || 'Something went to wrong!',
                type: 'error',
            });
        }
        setLoading(false);
        setToaster(true);
    }

    return (
        <>
            {toaster && (
                <Toaster
                    title={toastError?.title}
                    type={toastError?.type}
                    message={toastError?.message}
                    handleCloseToaster={() => setToaster(false)}
                />
            )}
            <div className="edit-property-wrap">
                <div className="properties-step-box">
                    <div className="step-inner">
                        <div className="cds--row">
                            <div className="cds--col-lg-8 mb-15">
                                <TextFieldControl
                                    labelText="Email"
                                    placeholder="Email"
                                    name="email"
                                    id="email"
                                    value={userDetails?.email}
                                    onChange={fillUserDetails}
                                />
                                {addUserDetailFormError?.email && (
                                    <label className="red-text">
                                        {addUserDetailFormError?.email}
                                    </label>
                                )}
                            </div>
                            <div className="cds--col-lg-8 mb-15">
                                <TextFieldControl
                                    labelText="First Name"
                                    placeholder="First Name"
                                    name="first_name"
                                    id="first_name"
                                    value={userDetails?.first_name}
                                    onChange={fillUserDetails}
                                />
                                {addUserDetailFormError?.first_name && (
                                    <label className="red-text">
                                        {addUserDetailFormError?.first_name}
                                    </label>
                                )}
                            </div>
                            <div className="cds--col-lg-8 mb-15">
                                <TextFieldControl
                                    labelText="Last Name"
                                    placeholder="Last Name"
                                    name="last_name"
                                    id="last_name"
                                    value={userDetails?.last_name}
                                    onChange={fillUserDetails}
                                />
                                {addUserDetailFormError?.last_name && (
                                    <label className="red-text">
                                        {addUserDetailFormError?.last_name}
                                    </label>
                                )}
                            </div>
                            <div className="cds--col-lg-8 mb-15">
                                <DropdownComboBoxControl
                                    id={dynamicId()}
                                    name="role"
                                    label="Role"
                                    titleText="Role"
                                    items={roleMenuList}
                                    value={userDetails?.role}
                                    onChange={(e) => {
                                        setUserDetails((prev) => {
                                            return {
                                                ...prev,
                                                ["role"]: e?.selectedItem?.label.toLowerCase()
                                            }
                                        })
                                    }}
                                />
                                {addUserDetailFormError?.role && (
                                    <label className="red-text">
                                        {addUserDetailFormError?.role}
                                    </label>
                                )}
                            </div>
                            <div className="cds--col-lg-8 mb-15">
                                <ButtonControl kind="primary" onClick={addUserInvite}>
                                    Invite
                                </ButtonControl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-nowrap">
                <DataTableControl
                    filter={triggerUpdate}
                    columns={headingData}
                    getData={getTableData}
                    sortable
                    showPagination
                    loading={loading}
                />
            </div>
        </>
    );
};