import React, { useEffect, useState, useMemo } from 'react';
import ReviewPropertyComponent from '../../Properties/reviewProperty';
import { propertiesAPI } from '../../../API/properties';
import ButtonControl from '../../Common/Button';
import SiteHeading from '../../Common/SiteHeading';
import Toaster from '../../Common/Toaster';
import MainLoader from '../../Common/Loader/MainLoader';
import { breadcrumb } from '../../../Utils/breadcrumbMenus';
import { dynamicId } from '../../../Utils/common';
import RangeSliderControl from '../../Common/RangeSlider';
import DropdownControl from '../../Common/Dropdown/dropdown';
import SearchFilter from '../../Common/SearchFilter';
import { landlordAPI } from '../../../API/Landlord';

export default function ReviewProperty() {
  const [propertyLists, setPropertyLists] = useState([]);
  const [toaster, setToaster] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allLandlords, setAllLandlords] = useState([]);
  const [filter, setFilter] = useState({
    square_footage: {
      $gte: 1,
      $lt: 1000,
    },
    monthly_rent: {
      $gte: 1,
      $lt: 1000,
    }
  });
  const [filterValue, setFilterValue] = useState({
    square_footage: {
      $gte: 1,
      $lt: 1000
    },
    monthly_rent: {
      $gte: 1,
      $lt: 1000
    }
  });
  const [searchValue, setSearchValue] = useState('');
  const [toastError, setToastError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    getAllPropertyList(filter);
  }, [filter]);

  /* Only fetched when refresh 
     for performance optimization */
  useMemo(async () => {
    const res = await landlordAPI?.getAllReducedDataLandlords();
    setAllLandlords(res?.data);
  }, []);

  useEffect(() => {
    if (isSubmit) {
      handleAddFilter();
    }
  }, [isSubmit]);

  const handleChange = (e) => {
    setFilterValue(prev => ({
      ...prev,
      ...e,
    }));
    // isSubmit && setIsSubmit(false);
  };

  const handleChangeRange = (e) => {
    setFilterValue({
      ...filterValue,
      ...e,
    });
  };

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escaping special characters
  }

  function filterToRegexPattern(filter_value) {
    const escapedFilter = escapeRegExp(filter_value);
    const parts = escapedFilter.split(/\s+/); // Split full name into parts
    const regexParts = parts.map(part => `(?=.*${part})`).join('|');
    return `^${regexParts}.*$`;
  }

  const handleAddFilter = () => {
    let filterData = {};
    const value = Object.keys(filterValue).map((keyName) => {
      if (filterValue[keyName]) {
        filterData[keyName] = filterValue[keyName];
      }
    });
    const valueToBeFiltered = filterToRegexPattern(searchValue);
    const pattern = new RegExp(valueToBeFiltered, 'i');
    const filteredLanlordsIds = allLandlords?.filter((data) => {
      return (
        pattern.test(data.email)
        || pattern.test(data.first_name)
        || pattern.test(data.last_name)
      );
    }).map(data => data._id);

    filterData['landlord'] = {
      $in: filteredLanlordsIds
    }

    setFilter(filterData);
    return value;
  };

  const bedrooms = [
    {
      id: '6',
      label: 'All',
      value: null,
    },
    {
      id: '1',
      label: '1-2',
      value: '1-2',
    },
    {
      id: '2',
      label: '2-4',
      value: '2-4',
    },
    {
      id: '3',
      label: '4-7',
      value: '4-7',
    },
    {
      id: '4',
      label: '7-10',
      value: '7-10',
    },
    {
      id: '5',
      label: '10+',
      value: '10-10000000',
    },
  ];

  const getAllPropertyList = async (filter) => {
    setLoading(true);
    setIsSubmit(false);
    const $page = filter.$page || 1;
    const $limit = filter.$limit || 1000000000000000;
    const newFilter = { 
      filter: {
        ...filter,
        status: 'Submitted for Listing',
      },
      $page,
      $limit,
    };
    // filter.$page = filter.$page || 1;
    // filter.$limit = filter.$limit || 1000000000000000;
    // filter.filter = {
    //   status: 'Review',
    // };
    const res = await propertiesAPI?.getAllFileteredProperties(newFilter);
    if (res && res?.status === true) {
      setPropertyLists(res?.data || []);
      toaster && setToaster(false);
      setLoading(false);
    } else {
      setToastError({
        title: 'API Fail',
        message: res?.data || 'Something went to wrong!',
        type: 'error',
      });
      setToaster(true);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <MainLoader />}
      {toaster && (
        <Toaster
          title={toastError?.title}
          type={toastError?.type}
          message={toastError?.message}
          handleCloseToaster={() => setToaster(false)}
        />
      )}
      <SiteHeading
        Heading="Recently added property"
        menu={breadcrumb?.reviewPropertiesListPage}
      ></SiteHeading>
      <div className="property-wrap">
        <SearchFilter
          handleChange={(e) => setSearchValue(e)}
          handleSearch={() => setIsSubmit(true)}
          value={searchValue}
        />
        <div className="filter-wrap">
          <RangeSliderControl
            isSubmit={isSubmit}
            title="Price"
            step={1000}
            min={1}
            max={10000}
            value={{
              min: filterValue?.monthly_rent?.$gte,
              max: filterValue?.monthly_rent?.$lt,
            }}
            onChange={(e) => 
              e?.min || e?.max
                ? handleChangeRange({
                  monthly_rent: {
                    $gte: e?.min,
                    $lt: e?.max,
                  },
                })
                : handleChangeRange({ monthly_rent: null })
            }
          />
          <RangeSliderControl
            isSubmit={isSubmit}
            title="Size"
            step={1000}
            min={1}
            max={10000}
            value={{
              min: filterValue?.square_footage?.$gte,
              max: filterValue?.square_footage?.$lt,
            }}
            onChange={(e) =>
              e?.min || e?.max
                ? handleChangeRange({
                  square_footage: {
                    $gte: e?.min,
                    $lt: e?.max,
                  },
                })
                : handleChangeRange({ square_footage: null })
            }
          />
          <DropdownControl
            titleText="Bedrooms"
            className="bedroom-dropdown"
            items={bedrooms}
            onChange={(e) =>
              e?.selectedItem?.value
                ? handleChange({
                  bedrooms: {
                    $gte:
                      (e?.selectedItem?.value &&
                        e?.selectedItem?.value?.split('-')[0]) ||
                      null,
                    $lt:
                      (e?.selectedItem?.value &&
                        e?.selectedItem?.value?.split('-')[1]) ||
                      null,
                  },
                })
                : handleChange({
                  bedrooms: null,
                })
            }
            id={dynamicId()}
            label=""
          />
          <ButtonControl onClick={() => setIsSubmit(true)}>
            Filter
          </ButtonControl>
        </div>
        <div className="cds--row">
          {propertyLists &&
            propertyLists?.docs &&
            propertyLists?.docs?.length > 0 ? (
            propertyLists?.docs.map((data, i) => {
              return (
                <div className="cds--col-xlg-4 cds--col-lg-6" key={i}>
                  <ReviewPropertyComponent data={data} />
                </div>
              );
            })
          ) : (
            <b>Recently reviewed properties are available yet.</b>
          )}
        </div>
      </div>
    </>
  );
}
