import { Form, Stack, TextInput } from '@carbon/react';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { authAPI } from '../../API/Auth';
import MainLoader from '../../Components/Common/Loader/MainLoader';
import Toaster from '../../Components/Common/Toaster';
import { emailValidation } from '../../Utils/Regex';
import ButtonControl from '../Common/Button';
import { allImage } from '../../Utils/allImageImport';

const ForgotPasswordComponent = () => {
  const navigate = useNavigate();
  const [forgotPasswordForm, setForgotPasswordForm] = useState({
    email: '',
    code: '',
    password: '',
    confirmPassword: '',
  });
  const [toaster, setToaster] = useState(false);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [toastError, setToastError] = useState({});
  const [verifyError, setVerifyError] = useState({});

  const handleChange = (e) => {
    const { name, value } = e?.target || e;
    setForgotPasswordForm({
      ...forgotPasswordForm,
      [name]: value,
    });
  };

  const handleManageError = () => {
    const { email } = forgotPasswordForm;
    let error = {};
    if (!email) {
      error.email = 'Email is required';
    } else {
      const value = emailValidation(email);
      if (!value) {
        error.email = 'Please enter valid email';
      } else {
        delete error.email;
      }
    }
    return error;
  };

  const handleManageVerifyCodeError = () => {
    const { code } = forgotPasswordForm;
    let error = {};
    if (!code) {
      error.code = 'Code is required';
    } else if (code?.toString()?.length !== 6) {
      error.code = 'Code must be six character only';
    }
    return error;
  };

  const handleSubmit = async () => {
    let error = handleManageError();
    if (Object.keys(error).length) {
      setVerifyError(error);
      return;
    } else {
      setVerifyError({});
    }
    setLoading(true);
    const payload = {
      email: forgotPasswordForm?.email,
    };
    const res = await authAPI?.forgotPasswordSendCode(payload);
    if (res && res?.status === true) {
      setLoading(false);
      setToaster(false);
      setStep(2);
    } else {
      setLoading(false);
      setToastError({
        title: 'API Fail',
        message: res?.data?.message || 'Something went to wrong!',
        type: 'error',
      });
      setToaster(true);
    }
  };

  const handleRenderForm = () => {
    switch (step) {
      case 1:
        return handleForgotPasswordForm();

      case 2:
        return handleVerifyCodeForm();

      case 3:
        return handleChangePasswordForm();

      default:
        return handleForgotPasswordForm();
    }
  };

  const handleSendVerifyCode = async () => {
    let error = handleManageVerifyCodeError();
    if (Object.keys(error).length) {
      setVerifyError(error);
      return;
    } else {
      setVerifyError({});
    }
    setLoading(true);
    const payload = {
      email: forgotPasswordForm?.email,
      code: +forgotPasswordForm?.code,
    };
    const res = await authAPI?.forgotPasswordVerifyCode(payload);
    if (res && res?.status === true) {
      setLoading(false);
      setToaster(false);
      setStep(3);
    } else {
      setLoading(false);
      setToastError({
        title: 'API Fail',
        message: res?.data?.message || 'Something went to wrong!',
        type: 'error',
      });
      setToaster(true);
    }
  };

  const handleChangePasswordError = () => {
    const { password, confirmPassword } = forgotPasswordForm;
    let error = {};
    if (!password) {
      error.password = 'Password is required';
    }
    if (!confirmPassword) {
      error.confirmPassword = 'Confirm password is required';
    }
    if (password && confirmPassword && confirmPassword !== password) {
      error.confirmPassword = 'Confirm password is not match with password';
    }
    return error;
  };

  const handleChangePassword = async () => {
    let error = handleChangePasswordError();
    if (Object.keys(error).length) {
      setVerifyError(error);
      return;
    } else {
      setVerifyError({});
    }
    setLoading(true);
    const payload = {
      email: forgotPasswordForm?.email,
      code: +forgotPasswordForm?.code,
      password: forgotPasswordForm?.password,
    };

    const res = await authAPI?.forgotPassword(payload);
    if (res && res?.status === true) {
      setLoading(false);
      setToaster(false);
      navigate('/login');
    } else {
      setLoading(false);
      setToastError({
        title: 'API Fail',
        message: res?.data?.message || 'Something went to wrong!',
        type: 'error',
      });
      setToaster(true);
    }
  };

  const handleVerifyCodeForm = () => {
    return (
      <div className="login-box">
        <h2 className="title">Check your email</h2>
        <Form>
          <Stack gap={5}>
            <TextInput
              labelText="Code"
              placeholder="Code"
              name="code"
              value={forgotPasswordForm?.code || ''}
              onChange={handleChange}
              required={false}
              id="code"
            />
            {verifyError?.code && (
              <label className="red-text">{verifyError?.code}</label>
            )}
            <div className="button-wrap">
              <ButtonControl
                className="cursor-pointer"
                kind="primary"
                onClick={handleSendVerifyCode}
                tabIndex={0}
                type="button"
              >
                Continue
              </ButtonControl>
            </div>
            <div className="bottom-text">
              <p>
                Didn’t receive the email?{' '}
                <label className="cursor-pointer" onClick={handleSubmit}>
                  <b>Resend code</b>
                </label>
              </p>
            </div>
          </Stack>
        </Form>
      </div>
    );
  };

  const handleChangePasswordForm = () => {
    return (
      <div className="login-box">
        <h2 className="title">Create your new password</h2>
        <Form>
          <Stack gap={5}>
            <TextInput.PasswordInput
              labelText="New Password"
              placeholder="New password"
              name="password"
              value={forgotPasswordForm?.password || ''}
              onChange={handleChange}
              required={false}
              id="password"
            />
            {verifyError?.password && (
              <label className="red-text">{verifyError?.password}</label>
            )}
            <TextInput.PasswordInput
              labelText="Confirm Password"
              placeholder="Confirm Password"
              name="confirmPassword"
              value={forgotPasswordForm?.confirmPassword || ''}
              onChange={handleChange}
              required={false}
              id="confirmPassword"
            />
            {verifyError?.confirmPassword && (
              <label className="red-text">{verifyError?.confirmPassword}</label>
            )}
            <div className="button-wrap">
              <ButtonControl
                className="cursor-pointer"
                kind="primary"
                onClick={handleChangePassword}
                tabIndex={0}
                type="button"
              >
                Continue
              </ButtonControl>
            </div>
            <hr></hr>
            <div className="bottom-text">
              <p>
                Need help? <b>Contact</b>
              </p>
            </div>
          </Stack>
        </Form>
      </div>
    );
  };

  const handleForgotPasswordForm = () => {
    return (
      <div className="login-box">
        <h2 className="title">Having trouble logging in?</h2>
        <Form>
          <Stack gap={5}>
            <TextInput
              labelText="Email"
              placeholder="Email"
              name="email"
              value={forgotPasswordForm?.email || ''}
              onChange={handleChange}
              required={false}
              id="email"
            />
            {verifyError?.email && (
              <label className="red-text">{verifyError?.email}</label>
            )}
            <div className="button-wrap">
              <ButtonControl
                className="cursor-pointer"
                kind="primary"
                onClick={handleSubmit}
                tabIndex={0}
                type="button"
              >
                Continue
              </ButtonControl>
            </div>
            <div className="bottom-text">
              <p>
                Need help? <Link to="/login">Contact</Link>
              </p>
            </div>
          </Stack>
        </Form>
      </div>
    );
  };

  return (
    <div className="login-form">
      <div className="logo">
        <img src={allImage?.logo} alt="logo" />
        <span>Curated Tenancy</span>
      </div>
      {toaster && (
        <Toaster
          title={toastError?.title}
          type={toastError?.type}
          message={toastError?.message}
          handleCloseToaster={() => setToaster(false)}
        />
      )}
      {loading && <MainLoader />}
      {handleRenderForm()}
    </div>
  );
};

export default ForgotPasswordComponent;
