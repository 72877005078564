import { Information } from '@carbon/icons-react';
import { Form, Stack, TextInput } from '@carbon/react';
import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { authAPI } from '../../API/Auth';
import MainLoader from '../../Components/Common/Loader/MainLoader';
import Toaster from '../../Components/Common/Toaster';
import { setUserLoginDetails } from '../../Redux/Actions/User';
import { allImage } from '../../Utils/allImageImport';
import {
  setLoggedUser,
  setLoggedUserToken,
  userAuth,
} from '../../Utils/common';
import { emailValidation } from '../../Utils/Regex';
import ButtonControl from '../Common/Button';
import CheckboxControl from '../Common/Checkbox';

const LoginComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [loginForm, setLoginForm] = useState({ email: '', password: '' });
  const [toaster, setToaster] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastError, setToastError] = useState({});
  const [loginError, setLoginError] = useState({
    email: false,
    password: false,
  });

  useEffect(() => {
    if(localStorage.getItem('error')) {
      setToastError({
        title: 'API Fail',
        message: localStorage.getItem('error'),
        type: 'error',
      });
      setToaster(true);
      localStorage.removeItem('error');
    }
    if (window.location.pathname !== '/login') {
      window.location.href = '/login';
    }
    if (userAuth()) navigate('/home');
  }, [location]);

  const handleChange = (e) => {
    const { name, value } = e?.target || e;
    setLoginForm({
      ...loginForm,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    const { email, password } = loginForm;
    let error = {};
    if (!password) {
      error.password = 'Password is required';
    }
    if (!email) {
      error.email = 'Email is required';
    } else {
      const value = emailValidation(email);
      if (!value) {
        error.email = 'Please enter valid email';
      } else {
        delete error.email;
      }
    }

    if (Object.keys(error).length) {
      setLoginError(error);
      return;
    }
    setLoading(true);
    const res = await authAPI?.login(loginForm);
    if (res && res?.status === true) {
      setLoading(false);
      setToaster(false);
      if (res?.data) {
        const userData = jwt_decode(res?.data?.token);
        setLoggedUser(userData);
        setLoggedUserToken(res?.data?.token);
        dispatch(setUserLoginDetails(userData));
        navigate('/home');
      }
    } else {
      setLoading(false);
      localStorage.setItem('error', res?.data?.message || 'Something went to wrong!');
    }
  };

  return (
    <div className="login-form">
      <div className="logo">
        <img src={allImage?.logo} alt="logo" />
        <span>Curated Tenancy</span>
      </div>
      {toaster && (
        <Toaster
          title={toastError?.title}
          type={toastError?.type}
          message={toastError?.message}
          handleCloseToaster={() => setToaster(false)}
        />
      )}
      {loading && <MainLoader />}
      <div className="login-box">
        <h2 className="title">Sign In</h2>
        <Form>
          <Stack gap={5}>
            <TextInput
              labelText="Email"
              placeholder="Email"
              name="email"
              value={loginForm?.email || ''}
              onChange={handleChange}
              required={false}
              id="email"
            />
            {loginError?.email && (
              <label className="red-text">{loginError?.email}</label>
            )}
            <TextInput.PasswordInput
              id="password"
              labelText="Password"
              placeholder="Password"
              name="password"
              value={loginForm?.password || ''}
              onChange={handleChange}
              required={false}
            />
            {loginError?.password && (
              <label className="red-text">{loginError?.password}</label>
            )}
            <div className="remember">
              <div className="remember-inner">
                <CheckboxControl labelText="Remember me" id="remember" />
                <Information />
              </div>
            </div>
            <div className="button-wrap">
              <ButtonControl
                className="cursor-pointer"
                kind="primary"
                onClick={handleSubmit}
                tabIndex={0}
                type="button"
                fullWidth
              >
                Sign In
              </ButtonControl>
            </div>
          </Stack>
        </Form>
      </div>
    </div>
  );
};

export default LoginComponent;
