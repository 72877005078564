import { Form, Stack, TextInput } from '@carbon/react';
import jwt_decode from 'jwt-decode';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { authAPI } from '../../API/Auth';
import MainLoader from '../../Components/Common/Loader/MainLoader';
import Toaster from '../../Components/Common/Toaster';
import { setUserLoginDetails } from '../../Redux/Actions/User';
import { allImage } from '../../Utils/allImageImport';
import { setLoggedUser, setLoggedUserToken } from '../../Utils/common';
import { emailValidation, PhoneNumber } from '../../Utils/Regex';
import ButtonControl from '../Common/Button';

const SignupComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [signupForm, setSignupForm] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
  });
  const [toaster, setToaster] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastError, setToastError] = useState({});
  const [signupError, setSignupError] = useState({});

  const handleChange = (e) => {
    const { name, value } = e?.target || e;
    setSignupForm({
      ...signupForm,
      [name]: value,
    });
  };

  const handleManageError = () => {
    const { email, password, firstName, lastName, phoneNumber } = signupForm;
    let error = {};
    if (!firstName) error.firstName = 'First name is required';
    if (!lastName) error.lastName = 'Last Name is required';
    if (!password) error.password = 'Password is required';
    if (!phoneNumber) {
      error.phoneNumber = 'Phone Number is required';
    } else {
      const value = PhoneNumber(phoneNumber);
      if (!value) {
        error.phoneNumber = 'Please enter valid phone number';
      } else {
        delete error.phoneNumber;
      }
    }
    if (!email) {
      error.email = 'Email is required';
    } else {
      const value = emailValidation(email);
      if (!value) {
        error.email = 'Please enter valid email';
      } else {
        delete error.email;
      }
    }
    return error;
  };

  const handleSubmit = async () => {
    let error = handleManageError();
    if (Object.keys(error).length) {
      setSignupError(error);
      return;
    } else {
      setSignupError({});
    }
    setLoading(true);
    const payload = {
      first_name: signupForm?.firstName,
      last_name: signupForm?.lastName,
      email: signupForm?.email,
      phone: signupForm?.phoneNumber,
      password: signupForm?.password,
    };
    const res = await authAPI?.signup(payload);
    if (res && res?.status === false) {
      setLoading(false);
      setToastError({
        title: 'API Fail',
        message: res?.data?.message || 'Something went to wrong!',
        type: 'error',
      });
      setToaster(true);
    } else {
      setLoading(false);
      setToaster(false);
      if (res?.data) {
        const userData = jwt_decode(res?.data?.token);
        setLoggedUser(userData);
        setLoggedUserToken(res?.data?.token);
        dispatch(setUserLoginDetails(userData));
        if (userData?.email_verified === true) {
          navigate('/login');
        } else {
          navigate('/email-verify');
        }
      }
    }
  };

  return (
    <div className="login-form">
      <div className="logo">
        <img src={allImage?.logo} alt="logo" />
        <span>Curated Tenancy</span>
      </div>
      {toaster && (
        <Toaster
          title={toastError?.title}
          type={toastError?.type}
          message={toastError?.message}
          handleCloseToaster={() => setToaster(false)}
        />
      )}
      {loading && <MainLoader />}
      <div className="login-box">
        <h2 className="title">Signup</h2>
        <Form>
          <Stack gap={5}>
            <TextInput
              labelText="First Name"
              placeholder="First Name"
              name="firstName"
              value={signupForm?.firstName || ''}
              onChange={handleChange}
              required={true}
              id="firstName"
            />
            {signupError?.firstName && (
              <label className="red-text">{signupError?.firstName}</label>
            )}
            <TextInput
              labelText="Last Name"
              placeholder="Last Name"
              name="lastName"
              value={signupForm?.lastName || ''}
              onChange={handleChange}
              required={false}
              id="lastName"
            />
            {signupError?.lastName && (
              <label className="red-text">{signupError?.lastName}</label>
            )}
            <TextInput
              labelText="Phone Number"
              placeholder="Phone Number"
              name="phoneNumber"
              value={signupForm?.phoneNumber || ''}
              onChange={handleChange}
              required={false}
              id="phone"
            />
            {signupError?.phoneNumber && (
              <label className="red-text">{signupError?.phoneNumber}</label>
            )}
            <TextInput
              labelText="Email"
              placeholder="Email"
              name="email"
              value={signupForm?.email || ''}
              onChange={handleChange}
              required={false}
              id="email"
            />
            {signupError?.email && (
              <label className="red-text">{signupError?.email}</label>
            )}
            <TextInput.PasswordInput
              id="password"
              labelText="Password"
              placeholder="Password"
              name="password"
              value={signupForm?.password || ''}
              onChange={handleChange}
              required={false}
            />
            {signupError?.password && (
              <label className="red-text">{signupError?.password}</label>
            )}

            <div className="button-wrap">
              <ButtonControl
                className="cursor-pointer"
                kind="primary"
                onClick={handleSubmit}
                tabIndex={0}
                type="button"
              >
                Next
              </ButtonControl>
              <div id="signInDiv" className="sign-in-button"></div>
            </div>

            <div className="bottom-text">
              <p>
                Already have account? <Link to="/login">Log In</Link>
              </p>
            </div>
          </Stack>
        </Form>
      </div>
    </div>
  );
};

export default SignupComponent;
