import React from 'react';
import {
  Header,
  HeaderContainer,
  HeaderMenuButton,
  Theme,
} from '@carbon/react';
import Sidebar from '../Components/Pages/Sidebar';
import { allImage } from '../Utils/allImageImport';  

export const Layout = () => {
  return (
    <>
      <HeaderContainer
        render={({ isSideNavExpanded, onClickSideNavExpand }) => (
          <>
            <Theme>
              <Header aria-label="header">
                <HeaderMenuButton
                  aria-label="Open menu"
                  onClick={onClickSideNavExpand}
                  isActive={isSideNavExpanded}
                />
              </Header>
            </Theme>
            <div className='app-bar'>
              <div className="logo">
                <img src={allImage?.logo} alt="logo" />
                <span>Curated Tenancy</span>
              </div>
            </div>
            <Sidebar className={isSideNavExpanded ? 'sidebar-open' : ''} />
          </>
        )}
      />
    </>
  );
};
