import React from 'react';
import { useNavigate } from 'react-router-dom';
import { allImage } from '../../Utils/allImageImport';

const ReviewPropertyComponent = ({ data }) => {
  const {
    address,
    bedrooms,
    bathrooms,
    square_footage,
    images,
    status,
    createdAt,
  } = data || {};
  const navigate = useNavigate();

  const handleOpenDetails = () => {
    navigate(`/properties/property-detail/${data?._id}`, { state: data?._id });
  };

  const handleOpenEditPage = () => {
    navigate(`/properties/edit-property/${data?._id}`, { state: data?._id });
  };

  return (
    <>
      <div className="property-box cursor-pointer" onClick={handleOpenDetails}>
        <div className="property-img">
          <img src={images[0]?.thumbnail} alt="" />
          <div
            className={`category ${status === 'Submitted for Listing' ? 'review' : 'listed'}`}
          >
            {status}
          </div>
          <div className="property-option">
            <span onClick={handleOpenEditPage} className="cursor-pointer">
              <img src={allImage?.edit} alt="edit-icon" />
            </span>
            <span onClick={handleOpenDetails} className="cursor-pointer">
              <img src={allImage?.view} alt="view-icon" />
            </span>
          </div>
        </div>
        <div className="property-info">
          <span>{new Date(createdAt).toLocaleDateString()}</span>
          <p>{address}</p>
          <div className="property-features">
            <div className="property-facility">
              <p>
                <span>{bedrooms}</span> <img src={allImage?.badRoom} alt="" />
              </p>
              <p>
                <span>{bathrooms}</span> <img src={allImage?.bathRoom} alt="" />
              </p>
            </div>
            <div className="sqft">
              <span>{`${
                square_footage
                  ? square_footage?.toString()?.replace('sqft', '')
                  : '0'
              } sqft`}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ReviewPropertyComponent;
