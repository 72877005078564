import React from 'react';
import DatePickerControl from '../Common/DatePicker';
import TextFieldControl from '../Common/TextField';
import ButtonControl from '../Common/Button';
import { dynamicId, validateDate } from '../../Utils/common';
import moment from 'moment';
import { momentDateFormat } from '../../Utils/constants';
import DropdownComboBoxControl from '../Common/Dropdown/comboBox';

const ApplicantsDetailsComponent = ({
  applicantDetails,
  handleChange,
  handleUpdateApplicantDetails,
  disableBtn,
}) => {
  if (applicantDetails && !Object?.keys(applicantDetails)?.length) {
    return null;
  }

  const {
    household_income_people,
    adult_occupants,
    current_address,
    building,
    current_monthly_rent,
    date,
    dob,
    email,
    employer_name,
    first_name,
    how_long_worked,
    is_employed,
    last_name,
    leave_reason,
    minor_occupants,
    monthly_household_income,
    other_comments,
    phone,
    phone_supervisor_or_hr,
    property_owner_notes,
    where_hear_aboutUs,
    propertyOwner_contact_applicant,
    order,
    unit_price,
    sub_total,
    date_time,
    acknowledgement,
    billed_to,
    bill_address,
    payment_method,
    payment_email,
    phone_number,
    sku,
    quantity,
  } = applicantDetails || {};

  return (
    <div className="applicants-form">
      <div className="cds--row">
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Order #"
            placeholder="Order #"
            name="order"
            onChange={handleChange}
            id={dynamicId()}
            value={order || 0}
            type="number"
            min="0"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Unit Price"
            placeholder="Unit Price"
            name="unit_price"
            onChange={handleChange}
            id={dynamicId()}
            value={unit_price || 0}
            type="number"
            min="0"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Reason for leaving"
            placeholder="Reason for leaving"
            name="leave_reason"
            onChange={handleChange}
            id={dynamicId()}
            value={leave_reason || ''}
          />
        </div>
        <div className="cds--col mb-15">
          <DatePickerControl
            labelText="Date"
            name="date"
            value={
              date ? moment(validateDate(date)).format(momentDateFormat) : ''
            }
            id={dynamicId()}
            onChange={(e) => {
              const event = {
                target: {
                  name: 'date',
                  value: e && e?.length ? e?.[0] : '',
                },
              };
              handleChange(event);
            }}
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            id={dynamicId()}
            value={sub_total || 0}
            labelText="Subtotal"
            placeholder="Subtotal"
            name="sub_total"
            onChange={handleChange}
            type="number"
            min="0"
          />
        </div>
        <div className="cds--col mb-15">
          <DropdownComboBoxControl
            ariaLabel="ComboBox"
            label="Select"
            titleText="Are you Employed"
            items={[
              { id: 1, label: 'Yes' },
              { id: 2, label: 'No' },
            ]}
            id={dynamicId()}
            name="is_employed"
            value={is_employed ? 'Yes' : 'No'}
            onChange={(e) => {
              const event = {
                target: {
                  name: 'is_employed',
                  value: e?.selectedItem?.label === 'Yes' ? true : false,
                },
              };
              handleChange(event);
            }}
          />
        </div>
        <div className="cds--col mb-15">
          <DatePickerControl
            labelText="Date_time"
            name="Date_time"
            value={
              date_time
                ? moment(validateDate(date_time)).format(momentDateFormat)
                : ''
            }
            id={dynamicId()}
            onChange={(e) => {
              const event = {
                target: {
                  name: 'date_time',
                  value: e && e?.length ? e?.[0] : '',
                },
              };
              handleChange(event);
            }}
          />
        </div>
        <div className="cds--col mb-15">
          <DropdownComboBoxControl
            ariaLabel="Acknowledgement"
            label="Select"
            titleText="Acknowledgement"
            items={[
              { id: 1, label: 'Yes' },
              { id: 2, label: 'No' },
            ]}
            id={dynamicId()}
            name="acknowledgement"
            value={acknowledgement ? 'Yes' : 'No'}
            onChange={(e) => {
              const event = {
                target: {
                  name: 'acknowledgement',
                  value: e?.selectedItem?.label === 'Yes' ? true : false,
                },
              };
              handleChange(event);
            }}
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="How many people has an income in your household?"
            placeholder="How many people has an income in your household?"
            name="household_income_people"
            id={dynamicId()}
            value={household_income_people || 0}
            onChange={handleChange}
            type="number"
            min="0"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Billed to"
            placeholder="Billed to"
            name="billed_to"
            id={dynamicId()}
            value={billed_to || 0}
            onChange={handleChange}
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Full Name"
            placeholder="Full Name"
            name="fullName"
            id={dynamicId()}
            value={`${first_name} ${last_name}` || 0}
            onChange={handleChange}
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            value={monthly_household_income || 0}
            onChange={handleChange}
            id={dynamicId()}
            labelText="Monthly Combined household after-tax income"
            placeholder="Monthly Combined household after-tax income"
            name="monthly_household_income"
            type="number"
            min="0"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Bill address"
            placeholder="Bill address"
            name="bill_address"
            value={bill_address || ''}
            id={dynamicId()}
            onChange={handleChange}
          />
        </div>
        <div className="cds--col mb-15">
          <DatePickerControl
            labelText="Date of Birth"
            name="dob"
            value={
              dob ? moment(validateDate(dob)).format(momentDateFormat) : ''
            }
            id={dynamicId()}
            onChange={(e) => {
              const event = {
                target: {
                  name: 'dob',
                  value: e && e?.length ? e?.[0] : '',
                },
              };
              handleChange(event);
            }}
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Employer Name"
            placeholder="Employer Name"
            name="employer_name"
            value={employer_name || ''}
            id={dynamicId()}
            onChange={handleChange}
          />
        </div>
        <div className="cds--col mb-15">
          <DropdownComboBoxControl
            ariaLabel="Payment Method"
            label="Select"
            titleText="Payment Method"
            items={[
              { id: 1, label: 'apple pay' },
              { id: 2, label: 'google pay' },
              { id: 3, label: 'cc' },
              { id: 4, label: 'cash' },
            ]}
            id={dynamicId()}
            name="payment_method"
            value={payment_method}
            onChange={(e) => {
              const event = {
                target: {
                  name: 'payment_method',
                  value: e?.selectedItem?.label,
                },
              };
              handleChange(event);
            }}
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Email"
            placeholder="Email"
            name="email"
            onChange={handleChange}
            id={dynamicId()}
            value={email}
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Phone # of supervisor / HR"
            placeholder="Phone # of supervisor / HR"
            name="phone_supervisor_or_hr"
            id={dynamicId()}
            value={phone_supervisor_or_hr || ''}
            onChange={handleChange}
            type="number"
            min="0"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Payment Email"
            placeholder="Payment Email"
            name="payment_email"
            id={dynamicId()}
            value={payment_email || ''}
            onChange={handleChange}
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Phone"
            placeholder="Phone"
            name="phone"
            id={dynamicId()}
            value={phone || ''}
            onChange={handleChange}
            type="number"
            min="0"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="How long have you worked there?"
            placeholder="How long have you worked there?"
            name="how_long_worked"
            id={dynamicId()}
            value={how_long_worked || ''}
            onChange={handleChange}
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Phone Number"
            placeholder="Phone Number"
            name="phone_number"
            id={dynamicId()}
            value={phone_number || ''}
            onChange={handleChange}
            type="number"
            min="0"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            id={dynamicId()}
            value={adult_occupants || 0}
            labelText="# of adult occupants"
            placeholder="# of adult occupants"
            name="adult_occupants"
            onChange={handleChange}
            type="number"
            min="0"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Where did you hear about us?"
            placeholder="Where did you hear about us?"
            name="where_hear_aboutUs"
            value={where_hear_aboutUs}
            onChange={handleChange}
            id={dynamicId()}
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            id={dynamicId()}
            value={building || ''}
            onChange={handleChange}
            labelText="Building"
            placeholder="Building"
            name="building"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            id={dynamicId()}
            value={minor_occupants || 0}
            labelText="# of minor occupants"
            placeholder="# of minor occupants"
            name="minor_occupants"
            onChange={handleChange}
            type="number"
            min="0"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Other comments (optional)"
            placeholder="Other comments (optional)"
            name="other_comments"
            id={dynamicId()}
            value={other_comments || ''}
            onChange={handleChange}
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="SKU"
            placeholder="SKU"
            name="sku"
            id={dynamicId()}
            value={sku || 0}
            onChange={handleChange}
            type="number"
            min="0"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Current Address"
            placeholder="Current Address"
            name="current_address"
            value={current_address || ''}
            onChange={handleChange}
            id={dynamicId()}
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Property Owner Notes"
            placeholder="Property Owner Notes"
            name="property_owner_notes"
            value={property_owner_notes || ''}
            onChange={handleChange}
            id={dynamicId()}
          />
        </div>

        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Quantity"
            placeholder="Quantity"
            name="quantity"
            value={quantity || 0}
            onChange={handleChange}
            id={dynamicId()}
            type="number"
            min="0"
          />
        </div>
        <div className="cds--col mb-15">
          <TextFieldControl
            labelText="Current Monthly Rent"
            placeholder="Current Monthly Rent"
            name="current_monthly_rent"
            id={dynamicId()}
            value={current_monthly_rent || 0}
            onChange={handleChange}
            type="number"
            min="0"
          />
        </div>
        <div className="cds--col mb-15">
          <DropdownComboBoxControl
            ariaLabel="Property owner made contact to applicant"
            label="Select"
            titleText="Property owner made contact to applicant"
            items={[
              { id: 1, label: 'Yes' },
              { id: 2, label: 'No' },
            ]}
            id={dynamicId()}
            name="propertyOwner_contact_applicant"
            value={propertyOwner_contact_applicant ? 'Yes' : 'No'}
            onChange={(e) => {
              const event = {
                target: {
                  name: 'propertyOwner_contact_applicant',
                  value: e?.selectedItem?.label === 'Yes' ? true : false,
                },
              };
              handleChange(event);
            }}
          />
        </div>
      </div>
      <div className="cds--row">
        <div className="cds--col mb-15">
          <ButtonControl kind="primary" onClick={handleUpdateApplicantDetails} disabled={disableBtn}>
            Save
          </ButtonControl>
        </div>
      </div>
    </div>
  );
};

export default ApplicantsDetailsComponent;
