import React from 'react';
import { Modal as ModalComponent } from '@carbon/react';

export default function Modal(props) {
  const { title, children, onClose, open, className } = props;
  return (
    <div className={className}>
      <ModalComponent
        modalHeading={title}
        passiveModal
        open={open}
        onRequestClose={onClose}
      >
        {children}
      </ModalComponent>
    </div>
  );
}
