import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { propertiesAPI } from '../../../API/properties';
import { breadcrumb } from '../../../Utils/breadcrumbMenus';
import MainLoader from '../../Common/Loader/MainLoader';
import SiteHeading from '../../Common/SiteHeading';
import Toaster from '../../Common/Toaster';
import EditPropertyComponent from '../../Properties/editProperty';

let customMenu = [];
export default function EditProperty() {
  const location = useLocation();
  const { state, pathname } = location;
  const [details, setDetails] = useState({});
  const [toaster, setToaster] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastError, setToastError] = useState({});

  useEffect(() => {
    if (!customMenu.some(({ title }) => title === 'Edit Property')) {
      customMenu = [];
      customMenu = breadcrumb.propertiesDetailsPage.slice();
      customMenu.push({
        title: 'Edit Property',
        path: '/properties/name',
        redirect: false,
      });
    }
  }, [details]);

  useEffect(() => {
    if (state || pathname?.split('/')[3]) {
      handleGetPropertyValue(state || pathname?.split('/')[3]);
    }
  }, [state]);

  const handleGetPropertyValue = async (id) => {
    setLoading(true);
    const res = await propertiesAPI?.getSingleProperty(id);
    if (res && res?.status === true) {
      setDetails(res?.data || {});
      toaster && setToaster(false);
      setLoading(false);
    } else {
      setToastError({
        title: 'API Fail',
        message: res?.data || 'Something went to wrong!',
        type: 'error',
      });
      setToaster(true);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <MainLoader />}
      {toaster && (
        <Toaster
          title={toastError?.title}
          type={toastError?.type}
          message={toastError?.message}
          handleCloseToaster={() => setToaster(false)}
        />
      )}
      <SiteHeading Heading="Edit property" menu={customMenu} />
      <EditPropertyComponent {...{ details }} />
    </>
  );
}
