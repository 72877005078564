import { REMOVE_USERS_DETAILS, SET_USERS_DETAILS } from '../Constants';
const initState = {
  user: null,
};
const UserReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_USERS_DETAILS:
      return {
        ...state,
        user: action?.payload || {},
      };
    case REMOVE_USERS_DETAILS:
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
};
export default UserReducer;
