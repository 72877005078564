const homePage = [
  {
    title: 'Home',
    path: '/home',
    redirect: false,
  },
];
const invitePage = [
  {
    title: 'Home',
    path: '/home',
    redirect: true,
  },
  {
    title: 'Invite',
    path: '/invite',
    redirect: false,
  },
];
const landlordListPage = [
  {
    title: 'Home',
    path: '/home',
    redirect: true,
  },
  {
    title: 'Landlords',
    path: '/landlords',
    redirect: false,
  },
];
const landlordDetailsPage = [
  {
    title: 'Home',
    path: '/home',
    redirect: true,
  },
  {
    title: 'Landlords',
    path: '/landlords',
    redirect: true,
  },
];
const propertiesDetailsPage = [
  {
    title: 'Home',
    path: '/home',
    redirect: true,
  },
  {
    title: 'Properties',
    path: '/properties',
    redirect: true,
  },
];
const propertiesListPage = [
  {
    title: 'Home',
    path: '/home',
    redirect: true,
  },
  {
    title: 'Properties',
    path: '/properties',
    redirect: false,
  },
];

const unListPropertiesListPage = [
  {
    title: 'Home',
    path: '/home',
    redirect: true,
  },
  {
    title: 'Unlist Property',
    path: '/unListProperty',
    redirect: false,
  },
];

const reviewPropertiesListPage = [
  {
    title: 'Home',
    path: '/home',
    redirect: true,
  },
  {
    title: 'Property Review',
    path: '/reviewProperty',
    redirect: false,
  },
];
const applicantsDetailsListPage = [
  {
    title: 'Home',
    path: '/home',
    redirect: true,
  },
  {
    title: 'Applicants',
    path: '/applicants',
    redirect: false,
  },
  {
    title: 'Applicants Details',
    path: '/applicants/applicants-details',
    redirect: false,
  },
];
export const breadcrumb = {
  homePage,
  invitePage,
  landlordListPage,
  propertiesListPage,
  landlordDetailsPage,
  propertiesDetailsPage,
  unListPropertiesListPage,
  reviewPropertiesListPage,
  applicantsDetailsListPage,
};
