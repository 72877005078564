import React from 'react';
import { breadcrumb } from '../../../Utils/breadcrumbMenus';
import SiteHeading from '../../Common/SiteHeading';
import InvitesComponent from '../../invites';

export default function InvitePage() {
  return (
    <>
      <SiteHeading Heading="Invite" menu={breadcrumb?.invitePage} />
      <InvitesComponent />
    </>
  );
};