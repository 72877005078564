import React, { useState } from 'react';
import DataTableControl from '../Common/DataTable';
import moment from 'moment/moment';
import { dateFormatTable, momentDateFormat } from '../../Utils/constants';
import { allImage } from '../../Utils/allImageImport';
import { propertiesAPI } from '../../API/properties';
import { useLocation, useNavigate } from 'react-router-dom';
import Toaster from '../Common/Toaster';
import SearchFilter from '../Common/SearchFilter';

const LandlordDetailComponent = ({ details }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state, pathname } = location || {};
  const [filter, setFilter] = useState({});
  const [filterValue, setFilterValue] = useState({});
  const [loading, setLoading] = useState(false);
  const [toaster, setToaster] = useState(false);
  const [error, setError] = useState({
    title: '',
    message: '',
    timeOut: '',
    type: '',
    subTitle: '',
  });

  const getTableData = async (filter) => {
    if (state || pathname?.split('/')[3]) {
      setLoading(true);
      filter.$page = filter.$page || 1;
      filter.$limit = filter.$limit || 10;
      filter.filter = filter.filter || {};
      filter.filter = {
        ...filter.filter,
        landlord: state || pathname?.split('/')[3],
      };

      const res = await propertiesAPI?.getAllFileteredProperties(filter);
      if (res || !res) setLoading(false);
      if (res && res?.status === true) {
        toaster && setToaster(false);
        return res?.data;
      } else {
        setToaster(true);
        setError({
          ...error,
          title: 'API Fail',
          message: res?.data?.message || 'Something went to wrong!',
          type: 'error',
        });
        return [];
      }
    }
  };

  const handleChange = (e) => {
    setFilterValue({
      ...filterValue,
      ...e,
    });
  };

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escaping special characters
  }

  function filterToRegexPattern(filter_value) {
    const escapedFilter = escapeRegExp(filter_value);
    const parts = escapedFilter.split(/\s+/); // Split full name into parts
    const regexParts = parts.map(part => `(?=.*${part})`).join('|');
    return `^${regexParts}.*$`;
  }

  const handleAddFilter = () => {
    let filterData = {};
    Object.keys(filterValue).map((keyName) => {
      if (filterValue[keyName]) {
        filterData[keyName] = { $regex: filterToRegexPattern(filterValue[keyName]), $options: 'i' } ;
      }
    });
    setFilter(filterData);
  };

  const handleOpenDetailsPage = (id) => {
    navigate(`/properties/property-detail/${id}`, { state: id });
  };

  const handleOpenEditPage = (id) => {
    navigate(`/properties/edit-property/${id}`, { state: id });
  };

  const handleCloseToaster = () => {
    setToaster(false);
  };

  const headingData = [
    {
      key: 'id',
      sortable: true,
      header: '',
      render: (value) => {
        return (
          <div className="table-action">
            <span
              onClick={() => handleOpenDetailsPage(value)}
              className="cursor-pointer"
            >
              <img src={allImage?.view} alt="" />
            </span>
            <span
              onClick={() => handleOpenEditPage(value)}
              className="cursor-pointer"
            >
              <img src={allImage?.edit} alt="" />
            </span>
          </div>
        );
      },
    },
    {
      key: 'address',
      sortable: true,
      header: 'Address',
    },
    {
      key: 'createdAt',
      sortable: false,
      header: 'Date available',
      render: (value) => {
        return (
          <span> {(value && moment(value).format(dateFormatTable)) || ''}</span>
        );
      },
    },
    {
      key: 'monthly_rent',
      sortable: true,
      header: 'Price',
    },
    {
      key: 'bedrooms',
      sortable: true,
      header: 'Bedrooms',
    },
    {
      key: 'square_footage',
      sortable: true,
      header: 'Size',
      render: (value) => {
        return (
          <span>
            {' '}
            {value ? `${value?.toString()?.replace('sqft', '')} sqft` : 'N/A'}
          </span>
        );
      },
    },
  ];

  // const paymentHeadingData = [
  //   {
  //     key: 'action',
  //     sortable: true,
  //     header: '',
  //     render: () => {
  //       return (
  //         <div className="table-action">
  //           <span>
  //             <img src={allImage?.view} alt="" />
  //           </span>
  //           <span>
  //             <img src={allImage?.downloadIcon} alt="" />
  //           </span>
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     key: 'createdAt',
  //     sortable: false,
  //     header: 'Date',
  //     render: (value) => {
  //       return (
  //         <span> {(value && moment(value).format(dateFormatTable)) || ''}</span>
  //       );
  //     },
  //   },
  //   {
  //     key: 'id',
  //     sortable: true,
  //     header: 'Id',
  //   },
  //   {
  //     key: 'propertyName',
  //     sortable: true,
  //     header: 'Property Name',
  //   },
  //   {
  //     key: 'amount',
  //     sortable: false,
  //     header: 'Amount',
  //   },
  // ];

  const landlordList = [
    {
      name: 'Name',
      value: `${details?.first_name} ${details?.last_name}` || 'N/A',
    },
    {
      name: 'Email',
      value: details?.email || 'N/A',
    },
    {
      name: 'Phone number',
      value: details?.phone || 'N/A',
    },
    {
      name: 'Signup date',
      value: details?.createdAt
        ? moment(details?.createdAt).format(momentDateFormat)
        : 'N/A',
    },
    {
      name: 'Number of property',
      value: 'N/A',
    },
    {
      name: 'Subscription',
      value: details?.subscription_status || 'N/A',
    },
  ];

  return (
    <>
      {toaster && (
        <Toaster
          title={error?.title}
          message={error?.message}
          type={error?.type}
          handleCloseToaster={handleCloseToaster}
        />
      )}
      <ul className="lendlord-list mb-30">
        {landlordList.map((data, i) => {
          return (
            <li key={i}>
              <label>{data.name}</label>
              <span>{data.value}</span>
            </li>
          );
        })}
      </ul>
      <h3 className="heading-s1">Properties</h3>
      <SearchFilter
        handleChange={(e) => handleChange({ address: e })}
        handleSearch={handleAddFilter}
        value={filterValue?.address || ''}
      />
      <div className="table-nowrap mb-30">
        <DataTableControl
          filter={filter}
          columns={headingData}
          getData={getTableData}
          sortable
          showPagination
          loading={loading}
        />
      </div>

      {/* <h3 className="heading-s1">Payment detail</h3>
      <div className="table-nowrap">
        <DataTableControl
          // filter={filter}
          columns={paymentHeadingData}
          getData={getTableData}
          sortable
          showPagination
        />
      </div> */}
    </>
  );
};

export default LandlordDetailComponent;
