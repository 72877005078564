import React, { useEffect, useState } from 'react';
import MultiRangeSlider from 'multi-range-slider-react';
import { dynamicId } from '../../../Utils/common';
import { FormLabel } from '@carbon/react';

export default function RangeSliderControl({
  value,
  onChange,
  step,
  min,
  max,
  title,
  isSubmit,
}) {
  const [minValue, set_minValue] = useState(value?.min || 1);
  const [maxValue, set_maxValue] = useState(value?.max || 1000);

  useEffect(() => {
    if(isSubmit){
      onChange({ min: minValue, max: maxValue })
    }
  }, [isSubmit]);

  const handleInput = (e) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue); 
    onChange({ min: e.minValue, max: e.maxValue });
  };

  return (
    <React.Fragment key={dynamicId()}>
      <div className="range-slider-wrapper">
        {title && (
          <FormLabel>
            {title}
          </FormLabel>
        )}
        <MultiRangeSlider
          id={dynamicId()}
          baseClassName="multi-range-slider-black"
          min={min || 0}
          max={max || 1000}
          step={step || 50}
          ruler={true}
          label={true}
          preventWheel={false}
          minValue={minValue}
          maxValue={maxValue}
          onChange={(e) => {
            handleInput(e);
          }}
        />
      </div>
    </React.Fragment>
  );
}
