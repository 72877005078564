import { Pagination } from '@carbon/react';
import React from 'react';

export default function PaginationComponent(props) {
  const { pageSize, page, totalItems, handleChange } = props;
  return (
    <div>
      <Pagination
        backwardText="Previous page"
        forwardText="Next page"
        itemsPerPageText="Items per page:"
        page={page || 1}
        pageNumberText="Page Number"
        pageSize={pageSize || 10}
        pageSizes={[5, 10, 20, 30, 40, 50]}
        totalItems={totalItems || 0}
        onChange={handleChange}
      />
    </div>
  );
}
